import * as model from "../model";
import * as schema from "../schema";

export function FrontendAPI(axios: any) {
  return new (class APIService {
    // client for endpoint ping
    async ping(message: string): Promise<string> {
      return (
        await axios.get(`/backend/v1/ping/${message}/`, {
          params: {},
        })
      ).data;
    }

    async searchProducts(query: string): Promise<model.ProductMeta[]> {
      return (
        await axios.get(`/backend/v1/searchProducts/${query}/`, { params: {} })
      ).data;
    }
    // client for endpoint welcome
    async welcome(params: string): Promise<string> {
      return (
        await axios.get(`/backend/v1/welcome/${params}/`, {
          params: {},
        })
      ).data;
    }

    // client for endpoint getOrderData
    async getOrderData(id: string): Promise<schema.order> {
      return (
        await axios.get(`/backend/v1/getOrderData/${id}/`, { params: {} })
      ).data;
    }

    // client for endpoint saveOrder
    async saveOrder(order: model.Order): Promise<model.OrderResponse> {
      return (await axios.post(`/backend/v1/saveOrder/`, order)).data;
    }

    // client for endpoint makepayment
    async makepayment(uuid: string): Promise<model.PaymentForm> {
      return (
        await axios.get(`/backend/v1/makepayment/${uuid}/`, { params: {} })
      ).data;
    }

    // client for endpoint getProductsOfCategory
    async getProductsOfCategory(
      categoryId: number,
    ): Promise<model.ProductMeta[]> {
      return (
        await axios.get(`/backend/v1/getProductsOfCategory/${categoryId}/`, {
          params: {},
        })
      ).data;
    }

    // client for endpoint getCategories
    async getCategories(): Promise<Array<schema.product_category>> {
      return (
        await axios.get(`/backend/v1/getCategories/`, {
          params: {},
        })
      ).data;
    }
    // client for endpoint getArticles
    async getArticles(): Promise<Array<model.CompressedArticle>> {
      return (
        await axios.get(`/backend/v1/getArticles/`, {
          params: {},
        })
      ).data;
    }

    // client for endpoint findRecipe
    async findRecipe(value: string) {
      return (
        await axios.get(`/backend/v1/findRecipe/${value}/`, { params: {} })
      ).data;
    }
    // client for endpoint saveRecipe
    async saveRecipe(data: { rows: any }) {
      return (await axios.post(`/backend/v1/saveRecipe/`, data)).data;
    }
    // client for endpoint fetchAllAlv
    async fetchAllAlv(options: {
      limit: 1000;
      offset: 0;
    }): Promise<schema.alv[]> {
      return (await axios.post(`/backend/v1/fetchAllAlv/`, options)).data;
    }
    // client for endpoint fetchAllShopSettings
    async fetchAllShopSettings(options: {
      limit: number;
      offset: number;
    }): Promise<schema.shop_settings[]> {
      return (await axios.post(`/backend/v1/fetchAllShopSettings/`, options))
        .data;
    }
    // client for endpoint fetchAllArticleCategory
    async fetchAllArticleCategory(options: {
      limit: 1000;
      offset: 0;
    }): Promise<schema.article_category[]> {
      return (await axios.post(`/backend/v1/fetchAllArticleCategory/`, options))
        .data;
    }
    // client for endpoint fetchAllArticle
    async fetchAllArticle(options: {
      limit: 1000;
      offset: 0;
    }): Promise<schema.article[]> {
      return (await axios.post(`/backend/v1/fetchAllArticle/`, options)).data;
    }
    // client for endpoint fetchAllArticleParagraph
    async fetchAllArticleParagraph(options: {
      limit: 1000;
      offset: 0;
    }): Promise<schema.article_paragraph[]> {
      return (
        await axios.post(`/backend/v1/fetchAllArticleParagraph/`, options)
      ).data;
    }
    // client for endpoint fetchAllProduct
    async fetchAllProduct(options: {
      limit: 1000;
      offset: 0;
    }): Promise<schema.product[]> {
      return (await axios.post(`/backend/v1/fetchAllProduct/`, options)).data;
    }
    // client for endpoint fetchAllProductVariantType
    async fetchAllProductVariantType(options: {
      limit: 1000;
      offset: 0;
    }): Promise<schema.product_variant_type[]> {
      return (
        await axios.post(`/backend/v1/fetchAllProductVariantType/`, options)
      ).data;
    }
    // client for endpoint fetchAllProductVariant
    async fetchAllProductVariant(options: {
      limit: 1000;
      offset: 0;
    }): Promise<schema.product_variant[]> {
      return (await axios.post(`/backend/v1/fetchAllProductVariant/`, options))
        .data;
    }
    // client for endpoint fetchAllProductIdea
    async fetchAllProductIdea(options: {
      limit: 1000;
      offset: 0;
    }): Promise<schema.product_idea[]> {
      return (await axios.post(`/backend/v1/fetchAllProductIdea/`, options))
        .data;
    }
    // client for endpoint fetchAllProductCategory
    async fetchAllProductCategory(options: {
      limit: 1000;
      offset: 0;
    }): Promise<schema.product_category[]> {
      return (await axios.post(`/backend/v1/fetchAllProductCategory/`, options))
        .data;
    }
    // client for endpoint fetchAllFile
    async fetchAllFile(options: {
      limit: 1000;
      offset: 0;
    }): Promise<schema.file[]> {
      return (await axios.post(`/backend/v1/fetchAllFile/`, options)).data;
    }
  })();
}
