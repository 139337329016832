import * as React from "react";
import { StateConnector, IProps } from "../redux/reducers/AppState";
import {
  makeStyles,
  createStyles,
  Theme,
  createMuiTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import RemoveCircleOutlinedIcon from "@material-ui/icons/RemoveCircleOutlined";
import BackspaceIcon from "@material-ui/icons/Backspace";
import { History } from "history";
import * as model from "../model";
import { NavLink } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import { useAppContext } from "../utils";
import { OstoskoriItem } from "./components/Product";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ShareIcon from "@material-ui/icons/Share";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CardContent from "@material-ui/core/CardContent";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import { useImmer } from "use-immer";
import SearchIcon from "@material-ui/icons/Search";
import { SearchBar } from "./components/SearchBar";
import { useDebounce } from "react-use";
import {
  useParams,
  useLocation,
  useHistory,
  useRouteMatch,
  Link,
} from "react-router-dom";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import Hidden from "@material-ui/core/Hidden";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Chip from "@material-ui/core/Chip";
import "./Main.scss";
import { Categories } from "./Categories";
// import ReactPDF from "@react-pdf/renderer";
// import { LahetysLista } from "./LahetysLista";
// import { PDFViewer } from "@react-pdf/renderer";
import HorizontalLinearStepper from "./components/CartStep";
import Alert from "@material-ui/lab/Alert";

import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import { countries } from "./utils/countrylist";
import * as schema from "../schema";
import { blue } from "@material-ui/core/colors";
import { FrontendAPI } from "../backend/index";
import axios from "axios";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import { DataTable } from "./components/DataTable";

const API = FrontendAPI(axios);

const toCents = (value: number) => {
  return Math.round(value * 100);
};
const fromCents = (value: number) => {
  return value / 100;
};

const cardStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 345,
    },
    media: {
      height: 0,
      paddingTop: "56.25%", // 16:9
    },
    expand: {
      transform: "rotate(0deg)",
      marginLeft: "auto",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: "rotate(180deg)",
    },
    avatar: {},
  }),
);

const blueTheme = createMuiTheme({
  palette: {
    primary: blue,
  },
});

export interface MyCompProps extends IProps {}

interface MyCompState {
  categories: model.Category[];
  articles: model.Article[];
}

const CategoryLink = (props: {
  category: model.Category;
  active?: string | number;
}) => {
  const selectedCategoryId = props.active ? Number(props.active) : -1;
  const c = props.category;
  return (
    <div>
      <NavLink
        to={
          c.categoryid !== selectedCategoryId
            ? "/category/" + c.categoryid
            : "/"
        }
      >
        {c.name}
      </NavLink>
    </div>
  );
};

// props.match.params.orderid;

export const ShowOrder = (props: { id: string }) => {
  const appCtx = useAppContext();
  const [order, setOrder] = React.useState<schema.order>();
  const [invalidOrder, setInvalidOrder] = React.useState("");
  const [data, setData] = React.useState<model.Order>();

  const loadData = async () => {
    try {
      const ord = await API.getOrderData(props.id);
      setOrder(ord);
      setData(JSON.parse(ord.order_json));

      if (ord.status === "PAYMENT_SUCCESS") {
        appCtx.clearCart();
      }
    } catch (e) {
      setInvalidOrder("Invalid Order ID");
    }
  };
  React.useEffect(() => {
    loadData();
  }, []);

  if (!order || !data) {
    return invalidOrder ? (
      <Typography variant="h6" component="h6">
        <Alert severity="error">{invalidOrder}</Alert>
      </Typography>
    ) : null;
  }
  return (
    <>
      <Typography variant="h6" component="h6">
        {order.status === "PAYMENT_SUCCESS" ? (
          <Alert severity="info">Maksu onnistuneesti suoritettu</Alert>
        ) : null}
        {order.status !== "PAYMENT_SUCCESS" ? (
          <>
            <Alert severity="error">
              Maksu epäonnistui tai peruuntui, voit yrittää maksua uudelleen
            </Alert>
          </>
        ) : null}
      </Typography>
      {order.status !== "PAYMENT_SUCCESS" ? (
        <>
          <MaksaOstokset order={JSON.parse(order.order_json)} />
        </>
      ) : null}

      <Grid container spacing={3} style={{ padding: "1em" }}>
        {order.status === "PAYMENT_SUCCESS" ? (
          <Grid item xs={12}>
            <a href={"/backend/v1/pdf/" + order.order_md5} target="_blank">
              {" "}
              <PictureAsPdfIcon style={{ fontSize: "2em", color: "black" }} />
              <Typography>{" Lataa tilausvahvistus tästä"}</Typography>
            </a>
          </Grid>
        ) : null}
        {/* <Grid item xs={12}>
          <DataTable
            hideFilter={true}
            rows={data.items}
            keys={["product", "variant", "rowSum", "rowAlvPros"]}
            titles={{
              product: "Tuote",
              variant: "Tilattu määrä",
              rowSum: "Summa",
              rowAlvPros: "ALV%",
            }}
            render={{
              product: (row) => {
                return <span>{row.product.name}</span>;
              },
              variant: (row) => {
                return (
                  <span>
                    {row.cnt} x {row.variant.size + row.variant.name}
                  </span>
                );
              },
              rowSum: (row) => {
                return <span>{row.rowSum.toFixed(2)} EUR</span>;
              },
              rowAlvPros: (row) => {
                return <span>{row.rowAlvPros.toFixed(0)}%</span>;
              },
            }}
          />
        </Grid> */}
      </Grid>
    </>
  );
};

export const PaymentSuccess = () => {
  return (
    <>
      <Typography variant="h6" component="h6">
        <Alert severity="error">Payment Sucecss</Alert>
      </Typography>
    </>
  );
};

export const PaymenFailed = () => {
  return (
    <>
      <Typography variant="h6" component="h6">
        <Alert severity="error">Payment Failed</Alert>
      </Typography>
    </>
  );
};

export const KassaOtsikot = (props: {}) => {
  const appCtx = useAppContext();
  return (
    <Grid item xs={12}>
      <Grid container alignItems="center" spacing={1}>
        <Grid item xs={1}></Grid>
        <Grid item xs={4}>
          <Typography variant="body2" color="textSecondary" component="b">
            Tuote
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body2" color="textSecondary" component="b">
            Määrä
          </Typography>
        </Grid>

        <Grid item xs={1}>
          <Typography variant="body2" color="textSecondary" component="b">
            Yhteensä
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography variant="body2" color="textSecondary" component="b">
            ALV
          </Typography>
        </Grid>

        <Grid item xs={1} justify="flex-end"></Grid>
      </Grid>
    </Grid>
  );
};

export const KassaRivi = (props: {
  item: model.ShoppingCartItem;
  allowEdit?: boolean;
}) => {
  const appCtx = useAppContext();
  const [focused, setFocused] = React.useState(0);

  const [cnt, setCnt] = React.useState(0);
  const [cnt2, setCnt2] = React.useState(0);

  const classes = cardStyles({});
  const article = props.item.product;
  const history = useHistory();

  return (
    <Grid item xs={12}>
      <Grid container alignItems="center" spacing={1}>
        <Grid item xs={12}>
          <div>
            <Typography variant="body2" color="textSecondary" component="p">
              {article.name}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={4}>
          {props.allowEdit ? (
            <input
              style={{ width: "50px", fontSize: "1.2em" }}
              value={props.item.cnt}
              onChange={(e) => {
                const value = Number(e.target.value);
                console.log(value);
                if (!isNaN(value) && value >= 0) {
                  appCtx.changeItemCnt({ item: props.item, cnt: value });
                }
              }}
            />
          ) : (
            props.item.cnt
          )}{" "}
          x {props.item.variant.size} {props.item.variant.name}
        </Grid>

        <Grid item xs={3}>
          <Typography variant="body2" color="textSecondary" component="p">
            {props.item.rowSum.toFixed(2)} EUR
          </Typography>
        </Grid>

        <Grid item xs={1} justify="flex-end">
          <Button
            className="removeItem"
            onClick={() => appCtx.removeFromCart(props.item)}
          >
            Poista tuote
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

function ToimitusTiedot() {
  const appCtx = useAppContext();
  const history = useHistory();
  const [err, setErr] = React.useState("");
  const [paymentData, setPaymentData] = React.useState<model.PaymentForm>({});

  const [f, updateFormData] = useImmer(
    appCtx.order ||
      ({
        items: appCtx.cart,
        delivery: { country: "Suomi" } as model.Delivery,
        orderPvm: new Date().toLocaleDateString(),
      } as model.Order),
  );

  function validateEmail(email) {
    const re = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
    return re.test(email);
  }

  const T = () => true;
  const F = () => false;

  const fields = [
    {
      n: "Etunimi",
      value: () => f.delivery!.firstName,
      validate: () => f.delivery!.firstName,
      upd: (value) =>
        updateFormData((f) => {
          f.delivery!.firstName = value;
        }),
      req: T,
    },
    {
      n: "Sukunimi",
      value: () => f.delivery!.lastName,
      validate: () => f.delivery!.lastName,
      upd: (value) =>
        updateFormData((f) => {
          f.delivery!.lastName = value;
        }),
      req: T,
    },
    {
      n: "Yritys",
      value: () => f.delivery!.yritysNimi,
      validate: () => f.delivery!.yritysNimi,
      upd: (value) =>
        updateFormData((f) => {
          f.delivery!.yritysNimi = value;
        }),
      req: F,
    },
    {
      n: "Toimitusosoite",
      value: () => f.delivery!.deliveryAddress,
      validate: () => f.delivery!.deliveryAddress,
      upd: (value) =>
        updateFormData((f) => {
          f.delivery!.deliveryAddress = value;
        }),
      req: T,
    },
    {
      n: "Postinumero",
      value: () => f.delivery!.postNumber,
      validate: () => f.delivery!.postNumber,
      upd: (value) =>
        updateFormData((f) => {
          f.delivery!.postNumber = value;
        }),
      req: T,
    },
    {
      n: "Toimipaikka",
      value: () => f.delivery!.city,
      validate: () => f.delivery!.city,
      upd: (value) =>
        updateFormData((f) => {
          f.delivery!.city = value;
        }),
      req: T,
    },
    {
      n: "Puhelin",
      value: () => f.delivery!.phoneNumber,
      validate: () => f.delivery!.phoneNumber,
      upd: (value) =>
        updateFormData((f) => {
          f.delivery!.phoneNumber = value;
        }),
      req: T,
    },
    {
      n: "c:Maa",
      value: () => f.delivery!.country,
      validate: () => f.delivery!.country,
      upd: (value) =>
        updateFormData((f) => {
          f.delivery!.country = value;
        }),
      req: F,
    },
    {
      n: "Sähköposti",
      value: () => f.delivery!.emailAddress,
      validate: () => validateEmail(f.delivery!.emailAddress),
      upd: (value) =>
        updateFormData((f) => {
          f.delivery!.emailAddress = value;
        }),
      req: T,
    },
    {
      n: "cb:Toimitus noutamalla (Toimituskulut 0 EUR)",
      value: () => f.delivery!.pickupDelivery,
      validate: () => f.delivery!.pickupDelivery,
      upd: (value: boolean | string) =>
        updateFormData((f) => {
          f.delivery!.pickupDelivery = !!value;
        }),
      req: F,
    },
    {
      n: "ml:Lisätiedot",
      value: () => f.additionalInformation,
      validate: () => f.additionalInformation,
      upd: (value) =>
        updateFormData((f) => {
          f.additionalInformation = value;
        }),
      req: F,
    },
    {
      n: "cb:Olen lukenut toimitusehdot ja hyväksyn ne",
      value: () => f.acceptTermsAndConditions,
      validate: () => f.acceptTermsAndConditions,
      upd: (value) =>
        updateFormData((f) => {
          f.acceptTermsAndConditions = value;
        }),
      req: F,
    },
    {
      n: "cb:Haluan asiakaskirjeen sähköpostiini",
      value: () => f.wantMonhtlyDigest,
      validate: () => f.wantMonhtlyDigest,
      upd: (value) =>
        updateFormData((f) => {
          f.wantMonhtlyDigest = value;
        }),
      req: F,
    },
  ];

  React.useEffect(() => {
    validatedFields((prod) => {
      prod.forEach((f, index) => {
        prod[index].validated = fields[index].req()
          ? !!fields[index].validate()
          : true;
      });
    });
  }, [f]);

  const [validation, validatedFields] = useImmer(
    fields.map((f, i) => ({
      validated: fields[i].req() ? !!fields[i].validate() : true,
      error: false,
      msg: "",
      index: i,
    })),
  );

  return (
    <>
      {/* <CardHeader subheader={"Tilauksen toimitustiedot"} /> */}
      <form>
        <Grid container spacing={1}>
          <Grid xs={12} item style={{ padding: "0.2em" }}>
            <Typography variant="h3" color="textSecondary" component="b">
              Tilaajan tiedot
            </Typography>{" "}
          </Grid>
          {fields.map((field, index) => {
            const name = field.n;
            const [tag, txt] = field.n.split(":");
            if (tag === "c") {
              return (
                <Grid xs={12} item style={{ padding: "1em" }} key={name}>
                  <FormControl>
                    <InputLabel id="demo-simple-select-label">
                      Toimitusmaa
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={
                        countries.indexOf(String(fields[index].value())) >= 0
                          ? fields[index].value()
                          : "Suomi"
                      }
                      onChange={(e) => {
                        const v = String(e.target.value || "");
                        updateFormData((prod) => {
                          fields[index].upd(v);
                        });
                      }}
                    >
                      {countries.map((c) => {
                        return (
                          <MenuItem value={c} key={c}>
                            {c}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              );
            }
            if (tag === "cb") {
              return (
                <Grid xs={12} item style={{ padding: "0.2em" }} key={name}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name={txt}
                          checked={!!fields[index].value()}
                          onChange={(e) => {
                            const v = e.target.checked;
                            updateFormData((prod) => {
                              fields[index].upd(v);
                            });
                          }}
                        />
                      }
                      label={
                        txt.indexOf("toimitusehdot") > 0 ? (
                          <span>
                            Olen lukenut{" "}
                            <a href="/rekisteriseloste/" target="_blank">
                              toimitusehdot
                            </a>{" "}
                            ja hyväksyn ne
                          </span>
                        ) : (
                          txt
                        )
                      }
                    />
                  </FormGroup>
                </Grid>
              );
            }
            return (
              <Grid xs={12} item style={{ padding: "0.2em" }} key={name}>
                <TextField
                  id={name}
                  multiline={tag === "ml"}
                  label={txt || name}
                  fullWidth={true}
                  error={true}
                  helperText={
                    !validation[index].validated ? (
                      name + " on pakollinen tieto"
                    ) : (
                      <div style={{ height: "18px", color: "rgba(0,0,0,0)" }}>
                        .
                      </div>
                    )
                  }
                  variant="outlined"
                  value={fields[index].value() || ""}
                  onChange={(e) => {
                    const v = e.target.value;
                    console.log("changed ", fields[index]);
                    updateFormData((prod) => {
                      console.log("V: ", v);
                      fields[index].upd(v);
                    });
                  }}
                />
              </Grid>
            );
          })}

          {err ? (
            <Grid xs={12} style={{ padding: "1em" }}>
              <Typography variant="h6" component="h6">
                <Alert severity="error">{err}</Alert>
              </Typography>{" "}
            </Grid>
          ) : null}
          <Grid xs={12} item style={{ padding: "0.2em" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={async () => {
                const fieldsWithoutValues = validation.filter(
                  (f) => !f.validated,
                );

                if (fieldsWithoutValues.length > 0) {
                  setErr("Täydennä puuttuvat kentät!");
                  console.log(fieldsWithoutValues);
                  return;
                }

                if (!f.acceptTermsAndConditions) {
                  setErr("Ole hyvä ja hyväksy toimitusehdot");
                  return;
                }

                console.log(f);
                appCtx.setOrder(f);

                // const results = (await appCtx.saveOrder(
                //   f,
                // )) as model.OrderResponse;

                /*
                  if (results.errors.length === 0) {
                    const paymentMeta = (await appCtx.getPaymentData(
                      results.uuid,
                    )) as model.PaymentForm;
                    if (paymentMeta && paymentMeta.fields.length > 0) {
                      setPaymentData(paymentMeta);
                    } else {
                      // ERROR
                      setErr("Virheellinen maksu");
                    }
                  }
                  */

                // Save order information to the backend

                history.push("/checkout/3");
                window.scrollTo(0, 0);
              }}
            >
              <NavigateNextIcon />
              Tallenna
            </Button>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                width: "100%",
                gap: "20px",
              }}
            >
              {paymentData.paytrailResponse
                ? paymentData.paytrailResponse.providers.map((provider) => {
                    return (
                      <form action={provider.url} method="post">
                        {provider.parameters.map((p) => (
                          <input type="hidden" name={p.name} value={p.value} />
                        ))}
                        <button
                          style={{ minWidth: "150px", minHeight: "80px" }}
                        >
                          <img src={provider.icon} />
                        </button>
                      </form>
                    );
                  })
                : null}
            </div>
          </Grid>
        </Grid>
      </form>
    </>
  );
}

function MaksaOstokset(props: { order: model.Order }) {
  const appCtx = useAppContext();
  const history = useHistory();
  const [err, setErr] = React.useState("");
  const [info, setInfo] = React.useState<string[]>([]);
  const [paymentData, setPaymentData] = React.useState<model.PaymentForm>({});

  const getOrderData = async () => {
    const results = (await appCtx.saveOrder(
      props.order,
    )) as model.OrderResponse;
    if (results.errors.length === 0) {
      const formData = (await appCtx.getPaymentData(
        results.uuid,
      )) as model.PaymentForm;
      if (formData && formData.paytrailResponse) {
        setPaymentData(formData);
      } else {
        setErr("Maksulomakkeen luominen epäonnistui.");
      }
      // appCtx.clearCart();
    } else {
      console.log("errors", results.errors);
      results.errors.forEach((er) => {
        console.log(er);
      });
      setErr("Tilaustietojen tallennus epäonnistui");
      setInfo(results.errorDetails);
    }
  };
  React.useEffect(() => {
    appCtx.getCategories();
    getOrderData();
  }, []);

  return (
    <>
      {/* <CardHeader subheader={"Tilauksen toimitustiedot"} /> */}
      <Grid container spacing={1}>
        <Grid xs={12} item style={{ padding: "0.2em" }}>
          <Typography variant="h3" color="textSecondary" component="b">
            Valitse maksutapa
          </Typography>{" "}
        </Grid>
        <Grid xs={12} item style={{ padding: "0.2em" }}>
          {err ? (
            <Grid xs={12} style={{ padding: "1em" }}>
              <Typography variant="h6" component="h6">
                <Alert severity="error">{err}</Alert>
              </Typography>{" "}
            </Grid>
          ) : null}
          {info.length > 0
            ? info.map((t) => (
                <Grid key={t} xs={12} style={{ padding: "1em" }}>
                  <Typography variant="h6" component="h6">
                    <Alert severity="info">{t}</Alert>
                  </Typography>{" "}
                </Grid>
              ))
            : null}
        </Grid>
        <Grid xs={12} item style={{ padding: "0.2em" }}>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              width: "100%",
              gap: "20px",
            }}
          >
            {paymentData.paytrailResponse
              ? paymentData.paytrailResponse.providers.map((provider) => {
                  return (
                    <form action={provider.url} method="post">
                      {provider.parameters.map((p) => (
                        <input type="hidden" name={p.name} value={p.value} />
                      ))}
                      <button
                        style={{
                          minWidth: "150px",
                          minHeight: "75px",
                          border: "1px solid #eee",
                        }}
                      >
                        <img src={provider.icon} />
                      </button>
                    </form>
                  );
                })
              : null}
          </div>
        </Grid>

        <Grid xs={12} item style={{ padding: "0.2em" }}></Grid>
      </Grid>
    </>
  );
}

function TilausTiedot() {
  const appCtx = useAppContext();
  const history = useHistory();
  const discount = appCtx.shopSettings
    ? appCtx.shopSettings.general_discount_percentage
    : 0;
  const discountInv = (100 - discount) / 100;
  const sum = fromCents(
    appCtx.cart.reduce(
      (sum, item) => sum + toCents(item.variant.price * discountInv) * item.cnt,
      0,
    ),
  );
  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h3" color="textSecondary" component="b">
            Ostoskorin sisältö
          </Typography>
        </Grid>

        {appCtx.cart.length > 0 ? (
          <Grid item xs={12}>
            <Typography variant="h6" color="textSecondary" component="b">
              Yhteensä: {sum.toFixed(2)} EUR + Toimitusmaksu{" "}
              {appCtx.shopSettings.shipping_fee} EUR
            </Typography>
          </Grid>
        ) : null}

        {appCtx.cart.length > 0 ? (
          <Grid item xs={12}>
            <Button
              variant="contained"
              onClick={() => {
                history.goBack();
              }}
            >
              Jatka ostoksia
            </Button>
            &nbsp;
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                appCtx.newOrderFromCart();
                history.push("/checkout/2");
                window.scrollTo(0, 0);
              }}
            >
              <ShoppingCartIcon /> Siirry tilaamaan
            </Button>
            &nbsp;
            {/* <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                console.log(appCtx.cart);
                const r = appCtx.saveCartAsRecipe();
                console.log(r);
                // hae ostoskorin sisältö
              }}
            >
              Tallenna reseptiksi
            </Button> */}
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Typography>Ostoskori on tyhjä</Typography>
          </Grid>
        )}

        {appCtx.cart.map((item) => (
          <Grid key={item.variant.id} item xs={12} md={3}>
            <OstoskoriItem key={item.variant.id} item={item} />
          </Grid>
        ))}

        {appCtx.cart.length > 0 ? (
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                appCtx.newOrderFromCart();
                history.push("/checkout/2");
                window.scrollTo(0, 0);
              }}
            >
              <NavigateNextIcon /> Tee tilaus
            </Button>
          </Grid>
        ) : null}
      </Grid>
    </>
  );
}

// const ShowList = React.memo((props: { order: model.Order }) => {
//   return (
//     <PDFViewer style={{ width: 600, height: 600 }}>
//       <LahetysLista order={props.order} />
//     </PDFViewer>
//   );
// });

export function Checkout(props: { step: number }) {
  const appCtx = useAppContext();
  const history = useHistory();
  const [order, setOrder] = React.useState<model.Order | null>(null);
  React.useEffect(() => {
    appCtx.getOrder().then(setOrder);
  }, []);
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {/* <HorizontalLinearStepper
            step={props.step - 1}
            onClick={(step) => {
              history.push("/checkout/" + (step + 1));
            }}
          /> */}

          {(() => {
            switch (props.step) {
              case 1:
                return <TilausTiedot />;
              case 2:
                return <ToimitusTiedot />;
              case 3:
                return (
                  <>
                    <MaksaOstokset order={appCtx.order} />
                  </>
                );
            }
            return null;
          })()}
        </Grid>
      </Grid>
    </>
  );
}
export const Main = Checkout;
