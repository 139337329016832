import * as React from "react";
import * as schema from "../../schema";
import { NavLink } from "react-router-dom";
import { Typography, Box } from "@material-ui/core";

export const CategoryLink = (props: {
  category: schema.product_category;
  active?: string | number;
  catSelected?: boolean;
}) => {
  const selectedCategoryId = props.active ? Number(props.active) : -1;
  const c = props.category;

  if (props.catSelected) {
    return (
      <div>
        <Typography>
          <Box fontWeight="fontWeightBold">{c.name}</Box>
        </Typography>
      </div>
    );
  }
  return (
    <div>
      <NavLink
        to={c.id !== selectedCategoryId ? "/category/" + c.id : "/"}
        onClick={() => {
          window.scrollTo(0, 0);
        }}
      >
        <Typography>{c.name}</Typography>
      </NavLink>
    </div>
  );
};
