import * as React from "react";
import { StateConnector, IProps } from "../redux/reducers/AppState";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

import { History } from "history";
import * as model from "../model";
import * as schema from "../schema";
import {
  BrowserRouter,
  Route,
  Switch,
  Link,
  Redirect,
  useHistory,
  NavLink,
} from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import { useAppContext } from "../utils";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useImmer } from "use-immer";
import SearchIcon from "@material-ui/icons/Search";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";

import { SearchBar } from "./components/SearchBar";
import { useDebounce } from "react-use";

import Hidden from "@material-ui/core/Hidden";
import "./Main.scss";
import { Categories } from "./Categories";
import { Checkout, ShowOrder } from "./Checkout";

import ListSubheader from "@material-ui/core/ListSubheader";
import { CategoryLink } from "./components/CategoryLink";
import Typography from "@material-ui/core/Typography";
import { OstettavaKohde, OstoskoriItem } from "./components/Product";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Alert from "@material-ui/lab/Alert";
import { Yhteystiedot, Rekisteriseloste } from "./content/common";

const topLogo = require("../assets/img/helmiq_ylaosa.jpg");

// import * as topLogo from "../assets/img/helmiq_ylaosa.jpg";

// @import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext');

// see also:
// https://developer.mozilla.org/en-US/docs/Learn/HTML/Multimedia_and_embedding/Responsive_images

const imageBase = () => `/cimages/`;
// const imageBase = () => `/tnimages/article`;

Number.prototype.toFixed = (() => {
  const toFixed = Number.prototype.toFixed;
  return function (decimals?: number) {
    return `${toFixed.apply(
      Math.round(this * Math.pow(10, decimals || 0)) /
        Math.pow(10, decimals || 0),
      [decimals],
    )}`;
  };
})();

const gridCatStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-around",
      overflow: "hidden",
      backgroundColor: theme.palette.background.paper,
    },
    gridList: {
      width: 500,
      height: 450,
    },
    icon: {
      color: "rgba(255, 255, 255, 0.54)",
    },
  }),
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      height: 140,
      width: 100,
    },
    control: {
      padding: theme.spacing(2),
    },
  }),
);

export interface MyCompProps extends IProps {
  match: {
    params: {
      categoryid?: number;
      search?: string;
      checkout?: string;
      paymentstatus?: string;
      orderid?: string;
    };
  };
  history: History;
}

interface MyCompState {
  categories: model.Category[];
  articles: model.Article[];
}

const HakuKentta = () => {
  const [searchValue, setSearchValue] = React.useState("");
  const history = useHistory();
  return (
    <form
      noValidate
      autoComplete="off"
      onSubmit={(e) => {
        history.push("/search/" + searchValue);
        e.preventDefault();
        return false;
      }}
    >
      <TextField
        label="Hae kaupasta!"
        value={searchValue}
        onChange={(e) => {
          // react router...
          setSearchValue(e.target.value);
        }}
      />
      <Button
        style={{ marginTop: "14px" }}
        onClick={() => {
          history.push("/search/" + searchValue);
        }}
      >
        <SearchIcon />
      </Button>
    </form>
  );
};

const RenderCategories = (params: {
  parentId: number | undefined;
  selectedIds: number[];
  level?: number;
}) => {
  const appCtx = useAppContext();
  const { parentId, selectedIds } = params;

  const catList = appCtx.categories.filter((c) => {
    if (!parentId) return !!!c.parent_id;
    return c.parent_id === parentId;
  });

  const level = params.level || 0;

  return (
    <>
      {catList.map((c) => {
        const catSelected = selectedIds.includes(c.id);
        return (
          <React.Fragment key={c.id}>
            <ListItem key={c.id} style={{ marginLeft: level * 10 }}>
              <CategoryLink category={c} catSelected={catSelected} />
            </ListItem>
            {catSelected ? (
              <RenderCategories
                parentId={c.id}
                selectedIds={selectedIds}
                level={(params.level || 0) + 1}
              />
            ) : null}
          </React.Fragment>
        );
      })}
    </>
  );
};

export function MainComp(props: MyCompProps) {
  const appCtx = useAppContext();
  const [categories, setCategories] = React.useState<schema.product_category[]>(
    [],
  );
  const [searchValue, setSearchValue] = React.useState("");
  const history = useHistory();
  const isPayment = !!props.match.params.paymentstatus;
  const isOrder = !!props.match.params.orderid;
  const loadData = async () => {
    const categories = await appCtx.getCategories();
    appCtx.calcCategoryArticles();
    setCategories(categories);
  };
  const getArticlesOfCat = async (catId: number) =>
    await appCtx.getproductsOfCategory(catId);
  React.useEffect(() => {
    loadData();
  }, []);
  React.useEffect(() => {
    if (props.match.params.categoryid) {
      getArticlesOfCat(props.match.params.categoryid);
    }
  }, [props.match.params.categoryid]);
  React.useEffect(() => {
    if (props.match.params.search) {
      appCtx.searchProducts(props.match.params.search);
    }
  }, [props.match.params.search]);

  const selectedCategoryId = Number(props.match.params.categoryid);
  const categoryCache = React.useMemo(() => {
    const categoryCache: { [key: number]: schema.product_category } = {};
    categories.forEach((c) => (categoryCache[c.id] = c));
    return categoryCache;
  }, [categories]);
  const selectedCat = categoryCache[selectedCategoryId];

  const viewArticles = React.useMemo(() => {
    if (!props.match.params.categoryid) {
      return [];
    }
    return (
      appCtx.productsOfCategory[Number(props.match.params.categoryid)] || []
    );
  }, [appCtx.productsOfCategory, props.match.params.categoryid]);

  const catArticles = appCtx.categoryArticles;
  const paramsCat = props.match.params.categoryid;
  const checkoutPage = Number(props.match.params.checkout || 0);
  const bIsCheckout = !!props.match.params.checkout;
  const bIsSearch = !!props.match.params.search;
  const hasChildren = (cat: schema.product_category) => {
    return !cat.parent_id || !categoryCache[cat.parent_id].parent_id;
  };
  const isCatView =
    !bIsCheckout &&
    !bIsSearch &&
    (selectedCat ? hasChildren(selectedCat) : true);
  const subCategories: schema.product_category[] = isCatView
    ? (() => {
        if (selectedCat) {
          return categories.filter((c) => c.parent_id === selectedCat.id);
        } else {
          return categories.filter((c) => !c.parent_id);
        }
      })()
    : [];
  const gridImageStyles = gridCatStyles({});

  if (Object.keys(appCtx.alvById).length === 0) {
    return null;
  }
  if (Object.keys(appCtx.categoryById).length === 0) {
    return null;
  }

  if (categories.length === 0 || !appCtx.shopSettings) {
    return null;
  }

  const getParentCat = (c: schema.product_category) => {
    return appCtx.categories.filter((p) => p.id === c.parent_id);
  };

  let currentCat = selectedCat;
  let categoryPath: number[] = [];

  while (currentCat) {
    categoryPath.push(currentCat.id);
    currentCat = getParentCat(currentCat).pop();
  }

  return (
    <>
      <div
        className="shophead"
        onClick={() => {
          history.push("/");
        }}
      >
        <img src={topLogo.default} />
      </div>
      <div style={{ width: "100%", textAlign: "center" }} className="shoplinks">
        <a href="/">Etusivu</a> | <a href="/yhteystiedot/">Yhteystiedot</a> |{" "}
        <a href="/rekisteriseloste/">Rekisteriseloste</a> |{" "}
        <a href="/checkout/1">Ostoskori</a>
      </div>

      {appCtx.shopSettings && appCtx.shopSettings.holiday_message && (
        <Grid container spacing={3} style={{ padding: "1em" }}>
          <Grid item xs={12}>
            <Alert>{appCtx.shopSettings.holiday_message}</Alert>
          </Grid>
        </Grid>
      )}

      <Grid container spacing={3} style={{ padding: "1em" }}>
        <Grid item xs={6} md={10}>
          <HakuKentta />
        </Grid>
        <Grid item xs={6} md={2}>
          <Grid container justify="flex-end">
            {appCtx.cart.length > 0 ? (
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  if (bIsCheckout) {
                    history.push("/");
                  } else {
                    history.push("/checkout/1");
                  }
                }}
              >
                <ShoppingCartIcon />{" "}
                <Hidden mdUp={true}>{`(${appCtx.cart.reduce(
                  (s, i) => s + 1,
                  0,
                )} kpl)`}</Hidden>
                <Hidden only={["xs", "sm"]}> Siirry Kassalle</Hidden>
              </Button>
            ) : null}
          </Grid>
        </Grid>
        <Hidden only={["xs", "sm"]}>
          <Grid item xs={3}>
            <RenderCategories parentId={undefined} selectedIds={categoryPath} />
          </Grid>
          {/* <Categories categoryId={props.match.params.categoryid} /> */}
        </Hidden>

        <Route path="/order/:orderid/">
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <ShowOrder id={props.match.params.orderid} />
          </Grid>
        </Route>

        <Route path="/yhteystiedot">
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <Typography>
              <Yhteystiedot />
            </Typography>
          </Grid>
        </Route>

        <Route path="/rekisteriseloste">
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <Typography>
              <Rekisteriseloste />
            </Typography>
          </Grid>
        </Route>

        {!isPayment && !isOrder && (
          <Grid item xs={12} sm={12} md={7} lg={7}>
            {selectedCat && !bIsCheckout ? (
              <Grid item>
                <h3>{selectedCat.name}</h3>
              </Grid>
            ) : null}
            <Grid container spacing={3} justify="flex-start">
              {bIsCheckout ? (
                <Checkout step={checkoutPage} />
              ) : subCategories.length > 0 ? (
                <>
                  {subCategories.map((c) => {
                    return (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        lg={4}
                        key={c.id}
                        onClick={() => {
                          history.push("/category/" + c.id);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          style={{ width: "100%" }}
                          src={imageBase() + c.image_uri + ".jpg"}
                          key={c.id}
                        />
                        <div className="categoryTitle">{c.name}</div>
                      </Grid>
                    );
                  })}
                </>
              ) : (
                <>
                  {appCtx.searchOn ? (
                    <Grid item xs={12}>
                      <CircularProgress />
                      <Typography>
                        Etsitään tuotteita hakusanalla "
                        {props.match.params.search}"
                      </Typography>
                    </Grid>
                  ) : null}
                  {bIsSearch &&
                  !appCtx.searchOn &&
                  appCtx.searchResult.length == 0 ? (
                    <Alert severity="info">
                      Ei tuloksia hakusanalla "{props.match.params.search}"
                    </Alert>
                  ) : null}
                  {(bIsSearch ? appCtx.searchResult : viewArticles).map(
                    (article) => (
                      <OstettavaKohde
                        key={article.product.id}
                        prodmeta={article}
                      />
                    ),
                  )}
                </>
              )}
            </Grid>
          </Grid>
        )}
        {!isPayment && (
          <Hidden only={["xs", "sm"]}>
            <Grid item xs={4} sm={2}>
              <div className="ostoskori">
                <div>
                  {appCtx.cart.map((item) => (
                    <OstoskoriItem key={item.variant.id} item={item} />
                  ))}
                </div>
                {appCtx.cart.length > 0 ? (
                  <Button
                    variant="contained"
                    color="default"
                    onClick={() => {
                      appCtx.clearCart();
                    }}
                  >
                    <ShoppingCartIcon /> Tyhjennä ostoskori
                  </Button>
                ) : null}
              </div>
            </Grid>
          </Hidden>
        )}
      </Grid>
    </>
  );
}
export const Main = MainComp;

export function PlainComp(props: MyCompProps) {
  const appCtx = useAppContext();
  const [categories, setCategories] = React.useState<schema.product_category[]>(
    [],
  );
  const [searchValue, setSearchValue] = React.useState("");
  const history = useHistory();
  const isPayment = !!props.match.params.paymentstatus;
  const isOrder = !!props.match.params.orderid;
  const loadData = async () => {
    const categories = await appCtx.getCategories();
    appCtx.calcCategoryArticles();
    setCategories(categories);
  };
  const getArticlesOfCat = async (catId: number) =>
    await appCtx.getproductsOfCategory(catId);
  React.useEffect(() => {
    loadData();
  }, []);
  React.useEffect(() => {
    if (props.match.params.categoryid) {
      getArticlesOfCat(props.match.params.categoryid);
    }
  }, [props.match.params.categoryid]);
  React.useEffect(() => {
    if (props.match.params.search) {
      appCtx.searchProducts(props.match.params.search);
    }
  }, [props.match.params.search]);

  const selectedCategoryId = Number(props.match.params.categoryid);
  const categoryCache = React.useMemo(() => {
    const categoryCache: { [key: number]: schema.product_category } = {};
    categories.forEach((c) => (categoryCache[c.id] = c));
    return categoryCache;
  }, [categories]);
  const selectedCat = categoryCache[selectedCategoryId];

  const viewArticles = React.useMemo(() => {
    if (!props.match.params.categoryid) {
      return [];
    }
    return (
      appCtx.productsOfCategory[Number(props.match.params.categoryid)] || []
    );
  }, [appCtx.productsOfCategory, props.match.params.categoryid]);

  const catArticles = appCtx.categoryArticles;
  const paramsCat = props.match.params.categoryid;
  const checkoutPage = Number(props.match.params.checkout || 0);
  const bIsCheckout = !!props.match.params.checkout;
  const bIsSearch = !!props.match.params.search;
  const hasChildren = (cat: schema.product_category) => {
    return !cat.parent_id || !categoryCache[cat.parent_id].parent_id;
  };
  const isCatView =
    !bIsCheckout &&
    !bIsSearch &&
    (selectedCat ? hasChildren(selectedCat) : true);
  const subCategories: schema.product_category[] = isCatView
    ? (() => {
        if (selectedCat) {
          return categories.filter((c) => c.parent_id === selectedCat.id);
        } else {
          return categories.filter((c) => !c.parent_id);
        }
      })()
    : [];
  const gridImageStyles = gridCatStyles({});

  if (Object.keys(appCtx.alvById).length === 0) {
    return null;
  }
  if (Object.keys(appCtx.categoryById).length === 0) {
    return null;
  }

  if (categories.length === 0 || !appCtx.shopSettings) {
    return null;
  }

  const getParentCat = (c: schema.product_category) => {
    return appCtx.categories.filter((p) => p.id === c.parent_id);
  };

  let currentCat = selectedCat;
  let categoryPath: number[] = [];

  while (currentCat) {
    categoryPath.push(currentCat.id);
    currentCat = getParentCat(currentCat).pop();
  }

  return (
    <>
      <div
        className="shophead"
        onClick={() => {
          history.push("/");
        }}
      >
        <img src={topLogo.default} />
      </div>
      <div style={{ width: "100%", textAlign: "center" }} className="shoplinks">
        <a href="/">Etusivu</a> | <a href="/yhteystiedot/">Yhteystiedot</a> |{" "}
        <a href="/rekisteriseloste/">Rekisteriseloste</a> |{" "}
        <a href="/checkout/1">Ostoskori</a>
      </div>

      {appCtx.shopSettings && appCtx.shopSettings.holiday_message && (
        <Grid container spacing={3} style={{ padding: "1em" }}>
          <Grid item xs={12}>
            <Alert>{appCtx.shopSettings.holiday_message}</Alert>
          </Grid>
        </Grid>
      )}

      <Grid container spacing={3} style={{ padding: "1em" }}>
        <Grid item xs={6} md={10}>
          <HakuKentta />
        </Grid>
        <Grid item xs={6} md={2}>
          <Grid container justify="flex-end">
            {appCtx.cart.length > 0 ? (
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  if (bIsCheckout) {
                    history.push("/");
                  } else {
                    history.push("/checkout/1");
                  }
                }}
              >
                <ShoppingCartIcon />{" "}
                <Hidden mdUp={true}>{`(${appCtx.cart.reduce(
                  (s, i) => s + 1,
                  0,
                )} kpl)`}</Hidden>
                <Hidden only={["xs", "sm"]}> Siirry Kassalle</Hidden>
              </Button>
            ) : null}
          </Grid>
        </Grid>
        <Hidden only={["xs", "sm"]}>
          <Grid item xs={2}>
            <RenderCategories parentId={undefined} selectedIds={categoryPath} />
          </Grid>
          {/* <Categories categoryId={props.match.params.categoryid} /> */}
        </Hidden>

        <Route path="/yhteystiedot">
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <Typography>
              <Yhteystiedot />
            </Typography>
          </Grid>
        </Route>
        <Route path="/newsletter/unsubscribe">
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <Typography>
              <h2>Uutiskirje peruttu</h2>
              <p>
                Olemme vastaanottaneet pyynnön uutiskirjeen peruuttamisesta
                antamaanne sähköpostiosoitteeseen.
              </p>
            </Typography>
          </Grid>
        </Route>

        <Route path="/rekisteriseloste">
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <Typography>
              <Rekisteriseloste />
            </Typography>
          </Grid>
        </Route>
      </Grid>
    </>
  );
}
