import * as React from "react";
export function Rekisteriseloste() {
  return (
    <>
      <p>
        <h2>Toimitus- ja sopimusehdot sek&auml; rekisteriseloste</h2> Helmi Q
        -verkkokauppa toimittaa tilauksia sek&auml; Suomeen ett&auml;
        ulkomaille. Tilauksen voi tehd&auml; verkkokaupassa (ks.
        &quot;Tilausohjeet&quot;).
      </p>

      <p>
        {" "}
        Tilaukset voidaan maksaa heti tilauksen yhteydess&auml; kotimaisten
        pankkien korteilla. Nordean, Osuuspankin, Danske Bankin, Tapiolan,
        Aktian, Nooan, Paikallisosuuspankkien, S&auml;&auml;st&ouml;pankkien,
        Handelsbankenin, S-Pankin, &Aring;landsbankennin verkkomaksaminen
        sek&auml; luottokorttimaksaminen tapahtuu Paytrail Oyj:n sivuston
        kautta:
      </p>

      <p>Maksupalvelutarjoaja</p>

      <p>
        Maksunv&auml;lityspalvelun toteuttajana ja maksupalveluntarjoajana
        toimii Paytrail Oyj (2122839-7) yhteisty&ouml;ss&auml; suomalaisten
        pankkien ja luottolaitosten kanssa. Paytrail Oyj n&auml;kyy maksun
        saajana tiliotteella tai korttilaskulla ja v&auml;litt&auml;&auml;
        maksun kauppiaalle. Paytrail Oyj:ll&auml; on maksulaitoksen toimilupa.
        Reklamaatiotapauksissa pyyd&auml;mme ottamaan ensisijaisesti
        yhteytt&auml; tuotteen toimittajaan.
      </p>

      <p>
        Paytrail Oyj, y-tunnus: 2122839-7
        <br /> Innova 2<br /> Lutakonaukio 7<br /> 40100 Jyv&auml;skyl&auml;
        <br /> Puhelin: 0207 181830
      </p>

      <p>Verkkopankit</p>

      <p>
        Verkkopankkimaksamiseen liittyv&auml;n maksunv&auml;lityspalvelun
        toteuttaa Paytrail Oyj (2122839-7) yhteisty&ouml;ss&auml; suomalaisten
        pankkien ja luottolaitosten kanssa. K&auml;ytt&auml;j&auml;n kannalta
        palvelu toimii aivan kuten perinteinen verkkomaksaminenkin.
      </p>

      <p>
        Toimitamme tuotteet postiin 1-2 arkip&auml;iv&auml;n kuluessa
        siit&auml;, kun maksettu verkkokauppatilaus on saapunut meille.
        Poikkeuksellisista toimitusajoista esim. aleruuhkien vuoksi ilmoitamme
        aina verkkokaupan etusivulla.
      </p>

      <p>
        Perimme l&auml;hetyskuluina vain yhden kiinte&auml;n maksun, 2,95 euroa,
        joka sis&auml;lt&auml;&auml; kaikki toimitus-, pakkaus- ja
        postituskulut. Suomen rajojen ulkopuolelle l&auml;hetett&auml;viin
        tilauksiin lis&auml;&auml;mme 2,95 euron toimitusmaksun lis&auml;ksi
        ulkomaantoimitusmaksun, joka on 8 euroa. Pyyd&auml;mme huomioimaan,
        ett&auml; noutamaton paketti ei ole tilauksen peruutus. Noutamatta
        j&auml;tettyjen tilausten toimitus- ja palautusmaksut sek&auml;
        mahdolliset uudelleenl&auml;hetyskulut perit&auml;&auml;n asiakkaalta
        t&auml;ysim&auml;&auml;r&auml;isin&auml;.
      </p>

      <p>
        Helmi Q Oy ei vastaa tilausten katoamisesta tai
        ylim&auml;&auml;r&auml;isit&auml; toimituskuluista, mik&auml;li asiakas
        on antanut virheellisen tai puutteellisen toimitusosoitteen.
        Ty&ouml;sosoitteeseen tilattaessa tulee antaa my&ouml;s yrityksen nimi.
        Pyyd&auml;mme huomioimaan, ett&auml; posti ei
        p&auml;&auml;s&auml;&auml;nt&ouml;isesti toimita l&auml;hetyksi&auml;
        lomaosoitteeseen, ellei asiakas ole tehnyt v&auml;liaikaista
        osoitteenmuutosta sinne. My&ouml;s t&auml;llaisissa tapauksissa
        ylim&auml;&auml;r&auml;iset toimitus- ja palautuskulut
        perit&auml;&auml;n asiakkaalta.
      </p>

      <p>
        Helmien valmistuserien v&auml;lill&auml; voi olla eroja esimerkiksi
        v&auml;ris&auml;vyiss&auml;. T&auml;m&auml;n vuoksi suosittelemme
        tilaamaan samaan ty&ouml;h&ouml;n tulevat helmet yhdell&auml; kertaa.
        Helmi Q ei vastaa helmien ja tuotteiden v&auml;rinkestosta. Esimerkiksi
        hopeasisushelmet, hopeoidut ja kullatut tuotteet voivat tummua ajan
        kuluessa tai hankauksen/kosteuden/kemikalien vaikutuksesta.
      </p>

      <p>
        Kaikki valikoimamme metallihelmet ja korunosat t&auml;ytt&auml;v&auml;t
        EU:n asettaman korumetalleja ja niiden nikkelipitoisuuksia koskevan
        direktiivin ehdot.
      </p>

      <p>
        Verkkokauppamme tuotekuvien yhteydess&auml; on mahdollisuus katsoa
        tuotetta suurennoksena. Suurennoksilla olemme pyrkineet siihen,
        ett&auml; asiakas saisi mahdollisimman totuudenmukaisen kuvan
        tuotteistamme. Mik&auml;li tilaamasi tuote ei jostain syyst&auml;
        kuitenkaan vastaa odotuksiasi ja haluat palauttaa tuotteen on
        k&auml;ytt&auml;m&auml;tt&ouml;mill&auml; tuotteilla palautusoikeus 14
        vuorokauden ajan tuotteiden vastaanottamisesta. Palautusoikeus koskee
        vain t&auml;ysi&auml; pakkauksia.
        Palautusk&auml;yt&auml;nn&ouml;st&auml; voi tarvittaessa kysy&auml;
        tarkemmin joko s&auml;hk&ouml;postitse helmiq@helmiq.fi tai puhelimitse
        040 5086638. Tilaajan tulee olla yli 18-vuotias, t&auml;t&auml;
        nuoremman henkil&ouml;n tilaukset ja maksusuoritukset tulee tehd&auml;
        vain h&auml;nen huoltajansa toimesta.
      </p>

      <p>
        Helmi Q -verkkokaupan sivuilla olevien kuvien ja tekstien osittainenkin
        lainaaminen ilman Helmi Q Oy:n kirjallista lupaa on kielletty
        tekij&auml;noikeuslain nojalla.
      </p>

      <p>
        Pid&auml;t&auml;mme oikeuden muuttaa toimitus ja-sopimusehtoja
        tarvittaessa. Eri alojen ty&ouml;taistelutoimet voivat vaikuttaa
        tilausten toimitusaikoihin ja toimitusmaksujen suuruuteen.
      </p>

      <p>
        <h2>Rekisteriseloste</h2> Henkil&ouml;tietolain (523/1999) 10 &sect;:n
        mukainen rekisteriseloste
      </p>

      <p>
        Rekisterinpit&auml;j&auml;: <br />
        Helmi Q Oy <br />
        Loonamistontie 80
        <br /> 34130 Ylinen
      </p>

      <p>
        Rekisterist&auml; vastaava yhteyshenkil&ouml;: Pia Muhli Puh. 040 508
        6638
      </p>

      <p>Henkil&ouml;tietojen k&auml;sittelyn tarkoitus:</p>

      <p>
        Asiakasrekisteri&auml; yll&auml;pidet&auml;&auml;n laskutusta sek&auml;
        tilauksiin liittyv&auml;&auml; asiointia varten. Tilauksen tekeminen
        verkkokaupassa ei edellyt&auml; rekister&ouml;itymist&auml;
        asiakasrekisteriin.
      </p>

      <p>Rekisterin tietosis&auml;lt&ouml;:</p>

      <p>
        Asiakkaan etunimi, sukunimi, postiosoite, postinumero, postitoimipaikka,
        puhelinnumero, matkapuhelinnumero, s&auml;hk&ouml;postiosoite,
        tilaushistoria ja l&auml;hetysten seurantatiedot ja n&auml;iden
        lis&auml;ksi laskutusasiakkaiden kohdalla tarvittaessa
        henkil&ouml;tunnus. Asiakas voi halutessaan tilata asiakaskirjeen ja
        t&auml;ll&ouml;in rekisteriin tallennetaan asiakkaan antama lupa
        l&auml;hett&auml;&auml; markkinointiviestint&auml;&auml;. Asiakas voi
        pyyt&auml;&auml; tietojensa muuttamista tai poistamista
        rekisterist&auml; l&auml;hett&auml;m&auml;ll&auml; pyynn&ouml;n
        s&auml;hk&ouml;postiin muhli@helmiq.fi.
      </p>

      <p>Tietol&auml;hteet:</p>

      <p>Yhteystiedot tallennetaan asiakkaan tilauksen yhteydess&auml;.</p>

      <p>
        Tietojen luovutus tai siirto Euroopan unionin tai Euroopan talousalueen
        ulkopuolelle:
      </p>

      <p>
        Asiakkaiden tietoja ei luovuteta eik&auml; myyd&auml; muille
        osapuolille.
      </p>

      <p>Asiakasrekisterin suojaus:</p>

      <p>
        Tietojen k&auml;sittelyss&auml; ja varmuuskopioiden
        s&auml;ilytyksess&auml; noudatetaan henkil&ouml;tietolain mukaista
        laillisuutta ja huolellisuutta. Rekisterinpit&auml;j&auml;n
        tietoj&auml;rjestelm&auml; ja tiedostot on suojattu teknisill&auml;
        suojausmenetelmill&auml;. Rekisterin k&auml;ytt&ouml;
        edellytt&auml;&auml; henkil&ouml;kohtaista
        k&auml;ytt&auml;j&auml;tunnusta ja salasanaa. K&auml;ytt&ouml;oikeus
        voidaan my&ouml;nt&auml;&auml; vain Helmi Q Oy:n henkil&ouml;kuntaan
        kuuluvalle mik&auml;li h&auml;nen teht&auml;v&auml;ns&auml;
        edellytt&auml;v&auml;t rekisteritietojen k&auml;sittely&auml;.
      </p>
    </>
  );
}

export function Yhteystiedot() {
  return (
    <>
      <h2>Yhteystiedot</h2>
      Verkkokauppa Helmi Q
      <br />
      Helmi Q Oy
      <br />
      Y-tunnus 2291832-2
      <br />
      Loonamistontie 80 (varasto, ei asiakaskäyntejä)
      <br />
      34130 Ylinen
      <br />
      <br />
      <br />
      Sähköposti:
      <br />
      helmiq@helmiq.fi
      <br />
      <br />
      <br />
      Pia Muhli
      <br />
      muhli@helmiq.fi
      <br />
      Puh. 040-508 6638 (Ei tekstiviestejä)
      <br />
      Puhelut arkisin klo 10-16
      <br />
    </>
  );
}
