export const countries = [
  "Suomi",
  "Albania",
  "Algeria",
  "Amerikan Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antigua ja Barbuda",
  "Argentiina",
  "Armenia",
  "Aruba",
  "Australia",
  "Itävalta",
  "Azerbaidzan",
  "Bahama",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Valko-Venäjä",
  "Belgia",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bosnia ja Hertsegovina",
  "Botswana",
  "Brasilia",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Kambodia",
  "Kamerun",
  "Kanada",
  "Kap Verde",
  "Caymansaaret",
  "Keski-Afrikan tasavalta",
  "Tzad",
  "Chile",
  "Kiina",
  "Kolumbia",
  "Kongo",
  "Kongon demokraattinen tasavalta",
  "Cookinsaaret",
  "Costa Rica",
  "Kroatia",
  "Kuuba",
  "Kypros",
  "Tsekki",
  "Tanska",
  "Djibouti",
  "Dominica",
  "Dominikaaninen tasavalta",
  "Itä-Timor",
  "Ecuador",
  "Egypti",
  "El Salvador",
  "Päiväntasaajan Guinea",
  "Eritrea",
  "Viro",
  "Etiopia",
  "Färsaaret",
  "Fidzi",
  "Suomi",
  "Ranska",
  "Ranskan Guayana",
  "Ranskan Polynesia",
  "Gabon",
  "Gambia",
  "Georgia",
  "Saksa",
  "Ghana",
  "Gibraltar",
  "Iso-Britannia",
  "Kreikka",
  "Gränlanti ",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Kongo",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hongkong ",
  "Unkari",
  "Islanti",
  "Intia",
  "Indonesia",
  "Iran",
  "Irak",
  "Irlanti",
  "Israel",
  "Italia",
  "Norsunluurannikko",
  "Jamaika",
  "Japani",
  "Jersey",
  "Jordania",
  "Kazakstan",
  "Kenia",
  "Kiribati",
  "Kuwait",
  "Kirgisia",
  "Laos",
  "Latvia",
  "Libanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Liettua",
  "Luxemburg",
  "Macao",
  "Makedonia",
  "Madagaskar",
  "Malawi",
  "Malesia",
  "Malediivit",
  "Mali",
  "Malta",
  "Pohjois-Mariaanit",
  "Marshallinsaaret",
  "Martinique ",
  "Mauritania",
  "Mauritius",
  "Meksiko",
  "Mikronesia",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montserrat ",
  "Marokko",
  "Mosambik",
  "Namibia",
  "Nauru",
  "Nepal",
  "Alankomaat",
  "Alankomaiden Antillit ",
  "Uusi-Kaledonia",
  "Uusi-Seelanti",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Norfolkinsaari ",
  "Norja",
  "Oman",
  "Pakistan",
  "Palau",
  "Panama",
  "Papua-Uusi-Guinea",
  "Paraguay",
  "Peru",
  "Filippiinit",
  "Puola",
  "Portugali",
  "Puerto Rico",
  "Qatar",
  "Räunion ",
  "Romania",
  "Venäjä",
  "Ruanda",
  "Saint Lucia",
  "Saint Vincent ja Grenadiinit",
  "San Marino",
  "Saudi-Arabia",
  "Senegal",
  "Seychellit",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Salomonsaaret",
  "Etelä-Afrikka",
  "Etelä-Korea",
  "Espanja",
  "Sri Lanka",
  "Saint Kitts ja Nevis",
  "Sudan",
  "Suriname",
  "Swazimaa",
  "Ruotsi",
  "Sveitsi",
  "Syyria",
  "Taiwan",
  "Tadzikistan",
  "Tansania",
  "Thaimaa",
  "Togo",
  "Tonga",
  "Trinidad ja Tobago",
  "Tunisia",
  "Turkki",
  "Turkmenistan",
  "Turks- ja Caicossaaret",
  "Tuvalu",
  "Uganda",
  "Ukraina",
  "Arabiemiirikunnat",
  "Yhdysvallat",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Vatikaanivaltio",
  "Venezuela",
  "Vietnam",
  "Brittiläiset Neitsytsaaret",
  "Yhdysvaltain Neitsytsaaret ",
  "Wallis ja Futuna",
  "Samoa",
  "Jemen",
  "Sambia",
  "Zimbabwe",
  "Serbia",
  "Montenegro",
];
