// basic imports for the components
import * as React from "react";
import { useAppContext } from "../../utils";
import * as model from "../../model";
import * as schema from "../../schema";
import { useHistory, Link } from "react-router-dom";
import Modal from "@material-ui/core/Modal";

// immer
import { useImmer } from "use-immer";

// grid imports
import Grid from "@material-ui/core/Grid";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";

// text field and buttons
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

// card imports
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";

// icon imports
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";

// make styles
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";

// image base
const imageBase = () => `/cimages/`;

// styles
const cardStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 345,
    },
    media: {
      height: 0,
      paddingTop: "56.25%", // 16:9
    },
    expand: {
      transform: "rotate(0deg)",
      marginLeft: "auto",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: "rotate(180deg)",
    },
    avatar: {},
  }),
);

export const OstettavaKohde = React.memo(
  (props: { prodmeta: model.ProductMeta }) => {
    const appCtx = useAppContext();

    const [focused, setFocused] = React.useState(0);
    const [cnt, setCnt] = React.useState(0);
    const [cnt2, setCnt2] = React.useState(0);
    const [errMsg, setErrMsg] = React.useState("");
    const [infoMsg, setInfoMsg] = React.useState("");

    const [modalOpen, setOpenModal] = React.useState(false);

    const modStyle = modelStyle();

    const classes = cardStyles({});
    const article = props.prodmeta.product;

    const cat = appCtx.categoryById[article.category_id!];
    if (!cat) {
      return null;
    }
    if (!appCtx.alvById[cat.alv_id]) {
      return null;
    }
    const variants: model.ShoppingCartItem[] = (
      props.prodmeta.variants || []
    ).map((v) => {
      return {
        product: props.prodmeta.product,
        category: appCtx.categoryById[article.category_id!],
        variant: v,
        cnt: 0,
        rowSum: 0,
        rowAlvPros:
          appCtx.alvById[appCtx.categoryById[article.category_id!].alv_id]
            .percentage,
      };
    });
    /*
export interface ShoppingCartItem {
  uuid?: string;
  item: Article;
  price: number;
  cnt: number;
  size: number;
  unit: string;
  alv: number;
  rowSum?: number;
}
*/

    // const [items, setItems] = React.useState<model.ShoppingCartItem[]>(prices);
    const [items, setItems] = useImmer(variants);
    const itemCnt = items.reduce((prev, curr) => prev + curr.cnt, 0);

    if (appCtx.categories.length === 0) {
      return null;
    }
    return (
      <Grid item xs={12} sm={12} md={6} lg={4}>
        <Card>
          <div style={{ height: "120px" }}>
            <CardHeader
              style={{ height: "120px" }}
              // avatar={
              //   <Avatar aria-label="recipe" className={classes.avatar}>
              //     R
              //   </Avatar>
              // }
              action={
                <IconButton aria-label="settings">
                  <MoreVertIcon />
                </IconButton>
              }
              subheader={
                <i style={{ fontSize: "14px", color: "#666" }}>
                  {article.description}
                </i>
              }
              title={<div style={{ fontSize: "16px" }}>{article.name}</div>}
            />
          </div>
          <CardMedia
            onClick={() => {
              setOpenModal(true);
            }}
            className={classes.media}
            image={imageBase() + article.image_uri + ".jpg"}
          />

          <Modal
            open={modalOpen}
            onClose={() => {
              setOpenModal(false);
            }}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div style={getModalStyle()} className={modStyle.paper}>
              <Typography component="h4" id="simple-modal-title">
                {article.name}
              </Typography>
              <i style={{ fontSize: "14px", color: "#666" }}>
                {article.description}
              </i>
              <img
                src={imageBase() + article.image_uri + ".jpg"}
                onClick={() => {
                  setOpenModal(false);
                }}
              />
            </div>
          </Modal>
          <CardContent>
            {/* <Typography variant="body2" color="textSecondary" component="p">
            Lizards are a widespread group of squamate reptiles, with over 6,000
            species, ranging across all continents except Antarctica
          </Typography> */}
            <form className={classes.root} noValidate autoComplete="off">
              {errMsg ? <Alert severity="info">{errMsg}</Alert> : null}
              {infoMsg ? (
                <Alert severity="success">
                  {infoMsg}{" "}
                  <Link
                    onClick={() => {
                      window.scrollTo(0, 0);
                    }}
                    to={"/checkout/1"}
                  >
                    Katso ostoskori
                  </Link>
                </Alert>
              ) : null}
              <Grid container>
                {variants.map((p, index) => {
                  const cnt = items[index].cnt;
                  return (
                    <Grid item xs={12} key={p.variant.id}>
                      <Grid container>
                        <Grid item xs={8}>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            component="div"
                          >
                            {p.variant.size} {p.variant.name} /{" "}
                            {p.variant.price.toFixed(2)} EUR
                            <div className="strike">
                              {appCtx.shopSettings.general_discount_percentage
                                ? "Tarjoushinta " +
                                  (
                                    p.variant.price -
                                    p.variant.price *
                                      (appCtx.shopSettings
                                        .general_discount_percentage /
                                        100)
                                  ).toFixed(2) +
                                  " EUR"
                                : null}
                            </div>
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <input
                            style={{
                              border: "1px solid gray",
                              padding: "3px",
                              width: "50px",
                            }}
                            onFocus={() => {
                              setFocused(index + 1);
                            }}
                            onBlur={() => {
                              setFocused(0);
                            }}
                            min="0"
                            type="number"
                            value={!cnt ? "" : cnt}
                            onChange={(e) => {
                              if (
                                appCtx.shopSettings &&
                                appCtx.shopSettings.holiday_mode
                              ) {
                                setErrMsg("Kauppa on lomalla");
                                return;
                              }

                              const targetValue = e.target.value;
                              const p = items[index];
                              const newCnt =
                                Number(targetValue) * p.variant.size +
                                items
                                  .filter((item, i) => i != index)
                                  .map((i) => i.cnt * i.variant.size)
                                  .reduce((p, n) => p + n, 0) +
                                appCtx.cart
                                  .filter((c) => c.product.id === p.product.id)
                                  .map((r) => r.cnt * r.variant.size)
                                  .reduce((p, n) => p + n, 0);
                              if (newCnt > p.product.warehouse_cnt) {
                                setErrMsg(
                                  "Tuotetta ei valittua enempää varastossa",
                                );
                                return;
                              }
                              setErrMsg("");

                              setItems((prod) => {
                                prod[index].cnt = Number(targetValue);
                              });
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
              <Grid container style={{ marginTop: "3px" }}>
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={itemCnt === 0}
                  onClick={() => {
                    items.forEach((item) => {
                      if (item.cnt > 0) {
                        appCtx.addToCart({ ...item });
                      }
                    });
                    setItems((prod) => {
                      prod.forEach((item) => {
                        item.cnt = 0;
                      });
                    });
                    setInfoMsg("Tuote siirretty ostoskoriin!");
                    setCnt(0);
                    setCnt2(0);
                  }}
                >
                  <ShoppingCartIcon />
                  {cnt === 0 ? "Lisää ostoskoriin" : `Lisää ostoskoriin`}
                </Button>
              </Grid>
            </form>
          </CardContent>
        </Card>
      </Grid>
    );
  },
);

function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const modelStyle = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: "absolute",
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }),
);

export const OstoskoriItem = (props: { item: model.ShoppingCartItem }) => {
  const appCtx = useAppContext();
  const [focused, setFocused] = React.useState(0);

  const [cnt, setCnt] = React.useState(0);
  const [cnt2, setCnt2] = React.useState(0);
  const [modalOpen, setOpenModal] = React.useState(false);

  const classes = cardStyles({});
  const article = props.item.product;
  const history = useHistory();
  const p = props.item;
  const modStyle = modelStyle();

  return (
    <Grid item xs={12}>
      <Card>
        <CardHeader subheader={article.name} style={{ height: "60px" }} />
        <CardMedia
          className={classes.media}
          image={imageBase() + article.image_uri + ".jpg"}
          title={article.name}
          onClick={() => {
            setOpenModal(true);
            // history.push("/search/" + article.name);
          }}
        />
        <Modal
          open={modalOpen}
          onClose={() => {
            setOpenModal(false);
          }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div style={getModalStyle()} className={modStyle.paper}>
            <Typography component="h4" id="simple-modal-title">
              {article.name}
            </Typography>
            <img
              src={imageBase() + article.image_uri + ".jpg"}
              onClick={() => {
                setOpenModal(false);
              }}
            />
          </div>
        </Modal>

        <CardContent>
          <div>
            <div>
              <Typography>{`${props.item.cnt} x ${props.item.variant.size} ${props.item.variant.name} / ${props.item.variant.price} EUR`}</Typography>
              <div className="strike">
                {appCtx.shopSettings.general_discount_percentage
                  ? "Tarjoushinta " +
                    (
                      p.variant.price -
                      p.variant.price *
                        (appCtx.shopSettings.general_discount_percentage / 100)
                    ).toFixed(2) +
                    " EUR"
                  : null}{" "}
              </div>
            </div>
          </div>
          <Button
            variant="contained"
            color="default"
            onClick={() => appCtx.removeFromCart(props.item)}
          >
            Poista
          </Button>
        </CardContent>
      </Card>
    </Grid>
  );
};
