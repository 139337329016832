import * as React from "react";
import { render } from "react-dom";
import { Routes } from "./views/Routes";
import reduxThunk from "redux-thunk";
import { createStore, applyMiddleware, compose, AnyAction, Store } from "redux";
import { Provider } from "react-redux";
import { reducers } from "./redux/reducers";
import { AppStateProvider } from "./redux/reducers/AppState";
import { AppContextProvider } from "./utils";

const store = createStore(
  reducers,
  compose(
    applyMiddleware(reduxThunk),
    window["__REDUX_DEVTOOLS_EXTENSION__"]
      ? window["__REDUX_DEVTOOLS_EXTENSION__"]()
      : (f) => f,
  ),
);

render(
  <Provider store={store}>
    <AppStateProvider>
      <AppContextProvider>
        <div>
          <Routes />
        </div>
      </AppContextProvider>
    </AppStateProvider>
  </Provider>,
  document.getElementById("main"),
);
