/********************************************************************************
 *                                                                               *
 *   Redux Reducers and React Context API Provider/Consumer for state AppState   *
 *   Generated by ts2redux from Source file ../AppState.ts                       *
 *                                                                               *
 ********************************************************************************/

import * as immer from "immer";
import { connect } from "react-redux";
import { IState } from "./index";
import * as React from "react";
import { FrontendAPI } from "../../backend";
import * as model from "../../model";
import * as schema from "../../schema";
import * as _ from "lodash";
import axios from "axios";
import { Order } from "../../model";
const API = FrontendAPI(axios);

function getLocalStorageItem<T>(
  name: "shopping_cart" | "shopping_sum" | "order",
  defaultValue: T,
): T {
  return localStorage.getItem(name)
    ? JSON.parse(localStorage.getItem(name) || "null")
    : defaultValue;
}

let categoryLoader: Promise<schema.product_category[]> | null = null;

class Guid {
  static newGuid() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
      var r = (Math.random() * 16) | 0,
        v = c == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }
}

/**
 * @redux true
 */
export class AppState {
  idCounter = 1;
  cartTotalSum: number = getLocalStorageItem("shopping_sum", 0);
  articles: model.Article[] = [];
  categories: schema.product_category[] = [];

  categoryById: { [key: number]: schema.product_category } = {};
  categoryArticles: { [key: number]: model.Article[] } = {};

  shopSettings: schema.shop_settings = null;

  searchOn = false;
  searchResult: model.ProductMeta[] = [];

  productsOfCategory: { [key: number]: model.ProductMeta[] } = {};
  alvById: { [key: number]: schema.alv } = {};

  cart: model.ShoppingCartItem[] = getLocalStorageItem("shopping_cart", []);

  cartError: string = "";

  order?: model.Order = getLocalStorageItem<model.Order | undefined>(
    "order",
    undefined,
  );

  calcCategoryArticles() {
    if (!this.articles) {
      return;
    }
    this.articles.forEach(a => {
      if (a.categoryid) {
        if (!this.categoryArticles[a.categoryid]) {
          this.categoryArticles[a.categoryid] = [];
        }
        this.categoryArticles[a.categoryid].push(a);
      }
    });
  }

  newOrderFromCart() {
    this.order = this.order || {
      items: this.cart,
      delivery: {} as model.Delivery,
      orderPvm: new Date().toLocaleDateString(),
      shippingFee: this.shopSettings ? this.shopSettings.shipping_fee : 2.95,
      shippingFeelALV: this.shopSettings
        ? this.shopSettings.shipping_fee_alv
        : 24,
      discountPercentage: this.shopSettings
        ? this.shopSettings.general_discount_percentage
        : 0,
    };
    this.order.items = this.cart;
  }

  setOrder(o: model.Order) {
    if (this.shopSettings) {
      this.order = {
        ...o,
        shippingFeelALV: this.shopSettings.shipping_fee_alv,
        shippingFee: this.shopSettings.shipping_fee,
        discountPercentage: this.shopSettings.general_discount_percentage,
      };
    } else {
      this.order = { ...o };
    }
    this.updateCartSum();
    localStorage.setItem("order", JSON.stringify(this.order));
  }

  async saveCartAsRecipe() {
    const myCart = this.cart;
    console.log(JSON.stringify(myCart));
    const c = await API.saveRecipe({ rows: myCart });
    return c;
  }

  async findRecipe(value: string) {
    const myCart = this.cart;
    const c = await API.findRecipe(value);
    if (c) {
      this.cart = c;
      this.updateCartSum();
      this.newOrderFromCart();
    }
  }

  async saveOrder(o: model.Order) {
    if (!o.shippingFeelALV) {
      throw "Shipping fee was not set";
    }
    return await API.saveOrder(o);
  }

  async getPaymentData(uuid: string) {
    return await API.makepayment(uuid);
  }

  initOrderFromCart() {
    this.order = {
      uuid: Guid.newGuid(),
      items: this.cart,
      orderPvm: new Date().toDateString(),
      shippingFee: this.shopSettings ? this.shopSettings.shipping_fee : 2.95,
      shippingFeelALV: this.shopSettings
        ? this.shopSettings.shipping_fee_alv
        : 24,
      discountPercentage: this.shopSettings
        ? this.shopSettings.general_discount_percentage
        : 2.95,
    };
  }

  updateCartSum() {
    let sum = 0;
    this.cart.forEach(item => {
      sum += item.cnt * item.variant.price;
      item.rowSum = item.cnt * item.variant.price;
    });
    this.cartTotalSum = sum;
    if (this.order) {
      this.order.sumWithoutDelivery = sum;
    }
    localStorage.setItem(
      "shopping_cart_sum",
      JSON.stringify(this.cartTotalSum),
    );
  }

  async getOrder() {
    return {
      orderPvm: "2020-1010",
      items: this.cart,
    };
  }

  clearCart() {
    this.cart = [];
    localStorage.setItem("shopping_cart", JSON.stringify(this.cart));
    this.updateCartSum();
  }

  addToCart(item: model.ShoppingCartItem) {
    // check the saldo for the product
    if (item.cnt * item.variant.size > item.product.warehouse_cnt) {
      return;
    }
    const [oldItem] = this.cart.filter(c => c.variant.id === item.variant.id);
    if (oldItem) {
      if (
        (oldItem.cnt + item.cnt) * item.variant.size >
        item.product.warehouse_cnt
      ) {
        return;
      }
      oldItem.cnt += item.cnt;
    } else {
      this.cart.unshift(item);
    }
    this.updateCartSum();
    localStorage.setItem("shopping_cart", JSON.stringify(this.cart));
  }

  changeItemCnt(params: { item: model.ShoppingCartItem; cnt: number }) {
    const [oldItem] = this.cart.filter(
      c => c.variant.id === params.item.variant.id,
    );
    if (oldItem) {
      if (params.cnt * oldItem.variant.size > oldItem.product.warehouse_cnt) {
        return;
      }
      oldItem.cnt = params.cnt;
    }
    this.updateCartSum();
    localStorage.setItem("shopping_cart", JSON.stringify(this.cart));
  }

  removeFromCart(removeItem: model.ShoppingCartItem) {
    this.cart = this.cart.filter(item => {
      return item.variant.id !== removeItem.variant.id;
    });
    this.updateCartSum();
    localStorage.setItem("shopping_cart", JSON.stringify(this.cart));
  }

  setArticlesOfCat(options: {
    category: number;
    products: model.ProductMeta[];
  }) {
    this.productsOfCategory[options.category] = options.products;
  }

  async getproductsOfCategory(categoryId: number) {
    if (this.productsOfCategory[categoryId]) {
      return this.productsOfCategory[categoryId];
    }
    this.setArticlesOfCat({
      category: categoryId,
      products: await API.getProductsOfCategory(categoryId),
    });
    return this.productsOfCategory[categoryId];
  }

  updateCategoryHash() {
    this.categories.forEach(c => {
      this.categoryById[c.id] = c;
    });
  }

  updateAlvs(alvList: schema.alv[]) {
    for (const alv of alvList) {
      this.alvById[alv.id] = alv;
    }
  }

  async searchProducts(value: string) {
    console.log("Search ", value);
    this.searchOn = true;
    try {
      this.searchResult = await API.searchProducts(value);
    } catch (e) {}
    this.searchOn = false;
  }

  async getCategories() {
    if (this.categories.length > 0) {
      return this.categories;
    }
    const [settings] = await API.fetchAllShopSettings({ offset: 0, limit: 1 });
    if (settings) {
      this.shopSettings = settings;
    }
    const alvs = await API.fetchAllAlv({ limit: 1000, offset: 0 });
    this.updateAlvs(alvs);
    categoryLoader = categoryLoader || API.getCategories();
    this.categories = await categoryLoader;
    this.updateCategoryHash();
    return this.categories;
  }

  async getArticles() {
    return [];
    // if (this.cart.length > 0) {
    //   this.updateCartSum();
    // }
    // if (this.articles.length > 0) {
    //   return this.articles;
    // }
    // if (articeLoader) {
    //   await articeLoader;
    //   await new Promise(res => {
    //     setTimeout(res, 1);
    //   });
    //   return this.articles;
    // }
    // console.log("Calling get articles");
    // console.time("getArticles");
    // articeLoader = articeLoader || API.getArticles();
    // this.articles = (await articeLoader).map(item => {
    //   const [articleid, categoryid, heading, prices, props] = item;
    //   return {
    //     articleid,
    //     categoryid,
    //     heading,
    //     prices: prices.map(p => {
    //       return { name: p[0], value: p[1] };
    //     }),
    //     props: props.map(p => ({ name: p[0], intvalue: Number(p[1]) }))
    //   };
    // });
    // articeLoader = null;
    // console.timeEnd("getArticles");

    // this.updateCartSum();
    // return this.articles;
  }
}

export interface IContainerPropsMethods {
  calcCategoryArticles: () => any;
  newOrderFromCart: () => any;
  setOrder: (o: model.Order) => any;
  saveCartAsRecipe: () => any;
  findRecipe: (value: string) => any;
  saveOrder: (o: model.Order) => any;
  getPaymentData: (uuid: string) => any;
  initOrderFromCart: () => any;
  updateCartSum: () => any;
  getOrder: () => any;
  clearCart: () => any;
  addToCart: (item: model.ShoppingCartItem) => any;
  changeItemCnt: (params: { item: model.ShoppingCartItem; cnt: number }) => any;
  removeFromCart: (removeItem: model.ShoppingCartItem) => any;
  setArticlesOfCat: (options: {
    category: number;
    products: model.ProductMeta[];
  }) => any;
  getproductsOfCategory: (categoryId: number) => any;
  updateCategoryHash: () => any;
  updateAlvs: (alvList: schema.alv[]) => any;
  searchProducts: (value: string) => any;
  getCategories: () => any;
  getArticles: () => any;
}
export interface IAppState {
  idCounter: number;
  cartTotalSum: number;
  articles: model.Article[];
  categories: schema.product_category[];
  categoryById: {
    [key: number]: schema.product_category;
  };
  categoryArticles: {
    [key: number]: model.Article[];
  };
  shopSettings: schema.shop_settings;
  searchOn: boolean;
  searchResult: model.ProductMeta[];
  productsOfCategory: {
    [key: number]: model.ProductMeta[];
  };
  alvById: {
    [key: number]: schema.alv;
  };
  cart: model.ShoppingCartItem[];
  cartError: string;
  order?: model.Order;
}

export type IContainerPropsState = IAppState;
export interface IProps extends IContainerPropsState, IContainerPropsMethods {}

function pick<T, K extends keyof T>(o: T, ...props: K[]) {
  return props.reduce((a, e) => ({ ...a, [e]: o[e] }), {}) as Pick<T, K>;
}
export function mapStateToPropsWithKeys<K extends keyof IContainerPropsState>(
  state: IState,
  keys: K[],
): Pick<IContainerPropsState, K> {
  return pick(state.AppState as IContainerPropsState, ...keys);
}

export const mapStateToProps = (state: IState): IContainerPropsState => {
  return {
    idCounter: state.AppState.idCounter,
    cartTotalSum: state.AppState.cartTotalSum,
    articles: state.AppState.articles,
    categories: state.AppState.categories,
    categoryById: state.AppState.categoryById,
    categoryArticles: state.AppState.categoryArticles,
    shopSettings: state.AppState.shopSettings,
    searchOn: state.AppState.searchOn,
    searchResult: state.AppState.searchResult,
    productsOfCategory: state.AppState.productsOfCategory,
    alvById: state.AppState.alvById,
    cart: state.AppState.cart,
    cartError: state.AppState.cartError,
    order: state.AppState.order,
  };
};

function mapDispatchToPropsWithKeys<K extends keyof IContainerPropsMethods>(
  dispatch: any,
  keys: K[],
): Pick<IContainerPropsMethods, K> {
  return pick(mapDispatchToProps(dispatch), ...keys);
}

export const mapDispatchToProps = (dispatch: any): IContainerPropsMethods => {
  return {
    calcCategoryArticles: () => {
      return dispatch(RAppState.calcCategoryArticles());
    },
    newOrderFromCart: () => {
      return dispatch(RAppState.newOrderFromCart());
    },
    setOrder: (o: model.Order) => {
      return dispatch(RAppState.setOrder(o));
    },
    saveCartAsRecipe: () => {
      return dispatch(RAppState.saveCartAsRecipe());
    },
    findRecipe: (value: string) => {
      return dispatch(RAppState.findRecipe(value));
    },
    saveOrder: (o: model.Order) => {
      return dispatch(RAppState.saveOrder(o));
    },
    getPaymentData: (uuid: string) => {
      return dispatch(RAppState.getPaymentData(uuid));
    },
    initOrderFromCart: () => {
      return dispatch(RAppState.initOrderFromCart());
    },
    updateCartSum: () => {
      return dispatch(RAppState.updateCartSum());
    },
    getOrder: () => {
      return dispatch(RAppState.getOrder());
    },
    clearCart: () => {
      return dispatch(RAppState.clearCart());
    },
    addToCart: (item: model.ShoppingCartItem) => {
      return dispatch(RAppState.addToCart(item));
    },
    changeItemCnt: (params: { item: model.ShoppingCartItem; cnt: number }) => {
      return dispatch(RAppState.changeItemCnt(params));
    },
    removeFromCart: (removeItem: model.ShoppingCartItem) => {
      return dispatch(RAppState.removeFromCart(removeItem));
    },
    setArticlesOfCat: (options: {
      category: number;
      products: model.ProductMeta[];
    }) => {
      return dispatch(RAppState.setArticlesOfCat(options));
    },
    getproductsOfCategory: (categoryId: number) => {
      return dispatch(RAppState.getproductsOfCategory(categoryId));
    },
    updateCategoryHash: () => {
      return dispatch(RAppState.updateCategoryHash());
    },
    updateAlvs: (alvList: schema.alv[]) => {
      return dispatch(RAppState.updateAlvs(alvList));
    },
    searchProducts: (value: string) => {
      return dispatch(RAppState.searchProducts(value));
    },
    getCategories: () => {
      return dispatch(RAppState.getCategories());
    },
    getArticles: () => {
      return dispatch(RAppState.getArticles());
    },
  };
};

export function ConnectKeys<
  K extends keyof IAppState,
  J extends keyof IContainerPropsMethods
>(keys: K[], methods: J[]) {
  return connect(
    (state: IState) => mapStateToPropsWithKeys(state, keys),
    (dispatch: any) => mapDispatchToPropsWithKeys(dispatch, methods),
  );
}

export const StateConnector = connect(mapStateToProps, mapDispatchToProps);

const initAppState = () => {
  const o = new AppState();
  return {
    idCounter: o.idCounter,
    cartTotalSum: o.cartTotalSum,
    articles: o.articles,
    categories: o.categories,
    categoryById: o.categoryById,
    categoryArticles: o.categoryArticles,
    shopSettings: o.shopSettings,
    searchOn: o.searchOn,
    searchResult: o.searchResult,
    productsOfCategory: o.productsOfCategory,
    alvById: o.alvById,
    cart: o.cart,
    cartError: o.cartError,
    order: o.order,
  };
};
const initWithMethodsAppState = () => {
  const o = new AppState();
  return {
    idCounter: o.idCounter,
    cartTotalSum: o.cartTotalSum,
    articles: o.articles,
    categories: o.categories,
    categoryById: o.categoryById,
    categoryArticles: o.categoryArticles,
    shopSettings: o.shopSettings,
    searchOn: o.searchOn,
    searchResult: o.searchResult,
    productsOfCategory: o.productsOfCategory,
    alvById: o.alvById,
    cart: o.cart,
    cartError: o.cartError,
    order: o.order,
    calcCategoryArticles: o.calcCategoryArticles,
    newOrderFromCart: o.newOrderFromCart,
    setOrder: o.setOrder,
    saveCartAsRecipe: o.saveCartAsRecipe,
    findRecipe: o.findRecipe,
    saveOrder: o.saveOrder,
    getPaymentData: o.getPaymentData,
    initOrderFromCart: o.initOrderFromCart,
    updateCartSum: o.updateCartSum,
    getOrder: o.getOrder,
    clearCart: o.clearCart,
    addToCart: o.addToCart,
    changeItemCnt: o.changeItemCnt,
    removeFromCart: o.removeFromCart,
    setArticlesOfCat: o.setArticlesOfCat,
    getproductsOfCategory: o.getproductsOfCategory,
    updateCategoryHash: o.updateCategoryHash,
    updateAlvs: o.updateAlvs,
    searchProducts: o.searchProducts,
    getCategories: o.getCategories,
    getArticles: o.getArticles,
  };
};

/**
 * @generated true
 */
export class RAppState {
  private _state?: IAppState;
  private _dispatch?: <A extends {}, T extends {}>(action: A) => T;
  private _getState?: () => any;
  constructor(
    state?: IAppState,
    dispatch?: (action: any) => any,
    getState?: () => any,
  ) {
    this._state = state;
    this._dispatch = dispatch;
    this._getState = getState;
  }
  get idCounter(): number {
    if (this._getState) {
      return this._getState().AppState.idCounter;
    } else {
      if (this._state) {
        return this._state.idCounter;
      }
    }
    throw new Error("Invalid State in AppState_idCounter");
  }
  set idCounter(value: number) {
    if (this._state && typeof value !== "undefined") {
      this._state.idCounter = value;
    } else {
      // dispatch change for item idCounter
      if (this._dispatch) {
        this._dispatch({
          type: AppStateEnums.AppState_idCounter,
          payload: value,
        });
      }
    }
  }
  get cartTotalSum(): number {
    if (this._getState) {
      return this._getState().AppState.cartTotalSum;
    } else {
      if (this._state) {
        return this._state.cartTotalSum;
      }
    }
    throw new Error("Invalid State in AppState_cartTotalSum");
  }
  set cartTotalSum(value: number) {
    if (this._state && typeof value !== "undefined") {
      this._state.cartTotalSum = value;
    } else {
      // dispatch change for item cartTotalSum
      if (this._dispatch) {
        this._dispatch({
          type: AppStateEnums.AppState_cartTotalSum,
          payload: value,
        });
      }
    }
  }
  get articles(): model.Article[] {
    if (this._getState) {
      return this._getState().AppState.articles;
    } else {
      if (this._state) {
        return this._state.articles;
      }
    }
    throw new Error("Invalid State in AppState_articles");
  }
  set articles(value: model.Article[]) {
    if (this._state && typeof value !== "undefined") {
      this._state.articles = value;
    } else {
      // dispatch change for item articles
      if (this._dispatch) {
        this._dispatch({
          type: AppStateEnums.AppState_articles,
          payload: value,
        });
      }
    }
  }
  get categories(): schema.product_category[] {
    if (this._getState) {
      return this._getState().AppState.categories;
    } else {
      if (this._state) {
        return this._state.categories;
      }
    }
    throw new Error("Invalid State in AppState_categories");
  }
  set categories(value: schema.product_category[]) {
    if (this._state && typeof value !== "undefined") {
      this._state.categories = value;
    } else {
      // dispatch change for item categories
      if (this._dispatch) {
        this._dispatch({
          type: AppStateEnums.AppState_categories,
          payload: value,
        });
      }
    }
  }
  get categoryById(): {
    [key: number]: schema.product_category;
  } {
    if (this._getState) {
      return this._getState().AppState.categoryById;
    } else {
      if (this._state) {
        return this._state.categoryById;
      }
    }
    throw new Error("Invalid State in AppState_categoryById");
  }
  set categoryById(value: { [key: number]: schema.product_category }) {
    if (this._state && typeof value !== "undefined") {
      this._state.categoryById = value;
    } else {
      // dispatch change for item categoryById
      if (this._dispatch) {
        this._dispatch({
          type: AppStateEnums.AppState_categoryById,
          payload: value,
        });
      }
    }
  }
  get categoryArticles(): {
    [key: number]: model.Article[];
  } {
    if (this._getState) {
      return this._getState().AppState.categoryArticles;
    } else {
      if (this._state) {
        return this._state.categoryArticles;
      }
    }
    throw new Error("Invalid State in AppState_categoryArticles");
  }
  set categoryArticles(value: { [key: number]: model.Article[] }) {
    if (this._state && typeof value !== "undefined") {
      this._state.categoryArticles = value;
    } else {
      // dispatch change for item categoryArticles
      if (this._dispatch) {
        this._dispatch({
          type: AppStateEnums.AppState_categoryArticles,
          payload: value,
        });
      }
    }
  }
  get shopSettings(): schema.shop_settings {
    if (this._getState) {
      return this._getState().AppState.shopSettings;
    } else {
      if (this._state) {
        return this._state.shopSettings;
      }
    }
    throw new Error("Invalid State in AppState_shopSettings");
  }
  set shopSettings(value: schema.shop_settings) {
    if (this._state && typeof value !== "undefined") {
      this._state.shopSettings = value;
    } else {
      // dispatch change for item shopSettings
      if (this._dispatch) {
        this._dispatch({
          type: AppStateEnums.AppState_shopSettings,
          payload: value,
        });
      }
    }
  }
  get searchOn(): boolean {
    if (this._getState) {
      return this._getState().AppState.searchOn;
    } else {
      if (this._state) {
        return this._state.searchOn;
      }
    }
    throw new Error("Invalid State in AppState_searchOn");
  }
  set searchOn(value: boolean) {
    if (this._state && typeof value !== "undefined") {
      this._state.searchOn = value;
    } else {
      // dispatch change for item searchOn
      if (this._dispatch) {
        this._dispatch({
          type: AppStateEnums.AppState_searchOn,
          payload: value,
        });
      }
    }
  }
  get searchResult(): model.ProductMeta[] {
    if (this._getState) {
      return this._getState().AppState.searchResult;
    } else {
      if (this._state) {
        return this._state.searchResult;
      }
    }
    throw new Error("Invalid State in AppState_searchResult");
  }
  set searchResult(value: model.ProductMeta[]) {
    if (this._state && typeof value !== "undefined") {
      this._state.searchResult = value;
    } else {
      // dispatch change for item searchResult
      if (this._dispatch) {
        this._dispatch({
          type: AppStateEnums.AppState_searchResult,
          payload: value,
        });
      }
    }
  }
  get productsOfCategory(): {
    [key: number]: model.ProductMeta[];
  } {
    if (this._getState) {
      return this._getState().AppState.productsOfCategory;
    } else {
      if (this._state) {
        return this._state.productsOfCategory;
      }
    }
    throw new Error("Invalid State in AppState_productsOfCategory");
  }
  set productsOfCategory(value: { [key: number]: model.ProductMeta[] }) {
    if (this._state && typeof value !== "undefined") {
      this._state.productsOfCategory = value;
    } else {
      // dispatch change for item productsOfCategory
      if (this._dispatch) {
        this._dispatch({
          type: AppStateEnums.AppState_productsOfCategory,
          payload: value,
        });
      }
    }
  }
  get alvById(): {
    [key: number]: schema.alv;
  } {
    if (this._getState) {
      return this._getState().AppState.alvById;
    } else {
      if (this._state) {
        return this._state.alvById;
      }
    }
    throw new Error("Invalid State in AppState_alvById");
  }
  set alvById(value: { [key: number]: schema.alv }) {
    if (this._state && typeof value !== "undefined") {
      this._state.alvById = value;
    } else {
      // dispatch change for item alvById
      if (this._dispatch) {
        this._dispatch({
          type: AppStateEnums.AppState_alvById,
          payload: value,
        });
      }
    }
  }
  get cart(): model.ShoppingCartItem[] {
    if (this._getState) {
      return this._getState().AppState.cart;
    } else {
      if (this._state) {
        return this._state.cart;
      }
    }
    throw new Error("Invalid State in AppState_cart");
  }
  set cart(value: model.ShoppingCartItem[]) {
    if (this._state && typeof value !== "undefined") {
      this._state.cart = value;
    } else {
      // dispatch change for item cart
      if (this._dispatch) {
        this._dispatch({ type: AppStateEnums.AppState_cart, payload: value });
      }
    }
  }
  get cartError(): string {
    if (this._getState) {
      return this._getState().AppState.cartError;
    } else {
      if (this._state) {
        return this._state.cartError;
      }
    }
    throw new Error("Invalid State in AppState_cartError");
  }
  set cartError(value: string) {
    if (this._state && typeof value !== "undefined") {
      this._state.cartError = value;
    } else {
      // dispatch change for item cartError
      if (this._dispatch) {
        this._dispatch({
          type: AppStateEnums.AppState_cartError,
          payload: value,
        });
      }
    }
  }
  get order(): model.Order | undefined {
    if (this._getState) {
      return this._getState().AppState.order;
    } else {
      if (this._state) {
        return this._state.order;
      }
    }
    return undefined;
  }
  set order(value: model.Order | undefined) {
    if (this._state && typeof value !== "undefined") {
      this._state.order = value;
    } else {
      // dispatch change for item order
      if (this._dispatch) {
        this._dispatch({ type: AppStateEnums.AppState_order, payload: value });
      }
    }
  }

  calcCategoryArticles() {
    if (this._state) {
      if (!this.articles) {
        return;
      }
      this.articles.forEach(a => {
        if (a.categoryid) {
          if (!this.categoryArticles[a.categoryid]) {
            this.categoryArticles[a.categoryid] = [];
          }
          this.categoryArticles[a.categoryid].push(a);
        }
      });
    } else {
      if (this._dispatch) {
        this._dispatch({ type: AppStateEnums.AppState_calcCategoryArticles });
      }
    }
  }

  public static calcCategoryArticles() {
    return (dispatcher: any, getState: any) => {
      return new RAppState(
        undefined,
        dispatcher,
        getState,
      ).calcCategoryArticles();
    };
  }
  newOrderFromCart() {
    if (this._state) {
      this.order = this.order || {
        items: this.cart,
        delivery: {} as model.Delivery,
        orderPvm: new Date().toLocaleDateString(),
        shippingFee: this.shopSettings ? this.shopSettings.shipping_fee : 2.95,
        shippingFeelALV: this.shopSettings
          ? this.shopSettings.shipping_fee_alv
          : 24,
        discountPercentage: this.shopSettings
          ? this.shopSettings.general_discount_percentage
          : 0,
      };
      this.order.items = this.cart;
    } else {
      if (this._dispatch) {
        this._dispatch({ type: AppStateEnums.AppState_newOrderFromCart });
      }
    }
  }

  public static newOrderFromCart() {
    return (dispatcher: any, getState: any) => {
      return new RAppState(undefined, dispatcher, getState).newOrderFromCart();
    };
  }
  setOrder(o: model.Order) {
    if (this._state) {
      if (this.shopSettings) {
        this.order = {
          ...o,
          shippingFeelALV: this.shopSettings.shipping_fee_alv,
          shippingFee: this.shopSettings.shipping_fee,
          discountPercentage: this.shopSettings.general_discount_percentage,
        };
      } else {
        this.order = { ...o };
      }
      this.updateCartSum();
      localStorage.setItem("order", JSON.stringify(this.order));
    } else {
      if (this._dispatch) {
        this._dispatch({ type: AppStateEnums.AppState_setOrder, payload: o });
      }
    }
  }

  public static setOrder(o: model.Order) {
    return (dispatcher: any, getState: any) => {
      return new RAppState(undefined, dispatcher, getState).setOrder(o);
    };
  }
  async saveCartAsRecipe() {
    const myCart = this.cart;
    console.log(JSON.stringify(myCart));
    const c = await API.saveRecipe({ rows: myCart });
    return c;
  }

  public static saveCartAsRecipe() {
    return (dispatcher: any, getState: any) => {
      return new RAppState(undefined, dispatcher, getState).saveCartAsRecipe();
    };
  }
  async findRecipe(value: string) {
    const myCart = this.cart;
    const c = await API.findRecipe(value);
    if (c) {
      this.cart = c;
      this.updateCartSum();
      this.newOrderFromCart();
    }
  }

  public static findRecipe(value: string) {
    return (dispatcher: any, getState: any) => {
      return new RAppState(undefined, dispatcher, getState).findRecipe(value);
    };
  }
  async saveOrder(o: model.Order) {
    if (!o.shippingFeelALV) {
      throw "Shipping fee was not set";
    }
    return await API.saveOrder(o);
  }

  public static saveOrder(o: model.Order) {
    return (dispatcher: any, getState: any) => {
      return new RAppState(undefined, dispatcher, getState).saveOrder(o);
    };
  }
  async getPaymentData(uuid: string) {
    return await API.makepayment(uuid);
  }

  public static getPaymentData(uuid: string) {
    return (dispatcher: any, getState: any) => {
      return new RAppState(undefined, dispatcher, getState).getPaymentData(
        uuid,
      );
    };
  }
  initOrderFromCart() {
    if (this._state) {
      this.order = {
        uuid: Guid.newGuid(),
        items: this.cart,
        orderPvm: new Date().toDateString(),
        shippingFee: this.shopSettings ? this.shopSettings.shipping_fee : 2.95,
        shippingFeelALV: this.shopSettings
          ? this.shopSettings.shipping_fee_alv
          : 24,
        discountPercentage: this.shopSettings
          ? this.shopSettings.general_discount_percentage
          : 2.95,
      };
    } else {
      if (this._dispatch) {
        this._dispatch({ type: AppStateEnums.AppState_initOrderFromCart });
      }
    }
  }

  public static initOrderFromCart() {
    return (dispatcher: any, getState: any) => {
      return new RAppState(undefined, dispatcher, getState).initOrderFromCart();
    };
  }
  updateCartSum() {
    if (this._state) {
      let sum = 0;
      this.cart.forEach(item => {
        sum += item.cnt * item.variant.price;
        item.rowSum = item.cnt * item.variant.price;
      });
      this.cartTotalSum = sum;
      if (this.order) {
        this.order.sumWithoutDelivery = sum;
      }
      localStorage.setItem(
        "shopping_cart_sum",
        JSON.stringify(this.cartTotalSum),
      );
    } else {
      if (this._dispatch) {
        this._dispatch({ type: AppStateEnums.AppState_updateCartSum });
      }
    }
  }

  public static updateCartSum() {
    return (dispatcher: any, getState: any) => {
      return new RAppState(undefined, dispatcher, getState).updateCartSum();
    };
  }
  async getOrder() {
    return {
      orderPvm: "2020-1010",
      items: this.cart,
    };
  }

  public static getOrder() {
    return (dispatcher: any, getState: any) => {
      return new RAppState(undefined, dispatcher, getState).getOrder();
    };
  }
  clearCart() {
    if (this._state) {
      this.cart = [];
      localStorage.setItem("shopping_cart", JSON.stringify(this.cart));
      this.updateCartSum();
    } else {
      if (this._dispatch) {
        this._dispatch({ type: AppStateEnums.AppState_clearCart });
      }
    }
  }

  public static clearCart() {
    return (dispatcher: any, getState: any) => {
      return new RAppState(undefined, dispatcher, getState).clearCart();
    };
  }
  addToCart(item: model.ShoppingCartItem) {
    if (this._state) {
      // check the saldo for the product
      if (item.cnt * item.variant.size > item.product.warehouse_cnt) {
        return;
      }
      const [oldItem] = this.cart.filter(c => c.variant.id === item.variant.id);
      if (oldItem) {
        if (
          (oldItem.cnt + item.cnt) * item.variant.size >
          item.product.warehouse_cnt
        ) {
          return;
        }
        oldItem.cnt += item.cnt;
      } else {
        this.cart.unshift(item);
      }
      this.updateCartSum();
      localStorage.setItem("shopping_cart", JSON.stringify(this.cart));
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: AppStateEnums.AppState_addToCart,
          payload: item,
        });
      }
    }
  }

  public static addToCart(item: model.ShoppingCartItem) {
    return (dispatcher: any, getState: any) => {
      return new RAppState(undefined, dispatcher, getState).addToCart(item);
    };
  }
  changeItemCnt(params: { item: model.ShoppingCartItem; cnt: number }) {
    if (this._state) {
      const [oldItem] = this.cart.filter(
        c => c.variant.id === params.item.variant.id,
      );
      if (oldItem) {
        if (params.cnt * oldItem.variant.size > oldItem.product.warehouse_cnt) {
          return;
        }
        oldItem.cnt = params.cnt;
      }
      this.updateCartSum();
      localStorage.setItem("shopping_cart", JSON.stringify(this.cart));
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: AppStateEnums.AppState_changeItemCnt,
          payload: params,
        });
      }
    }
  }

  public static changeItemCnt(params: {
    item: model.ShoppingCartItem;
    cnt: number;
  }) {
    return (dispatcher: any, getState: any) => {
      return new RAppState(undefined, dispatcher, getState).changeItemCnt(
        params,
      );
    };
  }
  removeFromCart(removeItem: model.ShoppingCartItem) {
    if (this._state) {
      this.cart = this.cart.filter(item => {
        return item.variant.id !== removeItem.variant.id;
      });
      this.updateCartSum();
      localStorage.setItem("shopping_cart", JSON.stringify(this.cart));
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: AppStateEnums.AppState_removeFromCart,
          payload: removeItem,
        });
      }
    }
  }

  public static removeFromCart(removeItem: model.ShoppingCartItem) {
    return (dispatcher: any, getState: any) => {
      return new RAppState(undefined, dispatcher, getState).removeFromCart(
        removeItem,
      );
    };
  }
  setArticlesOfCat(options: {
    category: number;
    products: model.ProductMeta[];
  }) {
    if (this._state) {
      this.productsOfCategory[options.category] = options.products;
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: AppStateEnums.AppState_setArticlesOfCat,
          payload: options,
        });
      }
    }
  }

  public static setArticlesOfCat(options: {
    category: number;
    products: model.ProductMeta[];
  }) {
    return (dispatcher: any, getState: any) => {
      return new RAppState(undefined, dispatcher, getState).setArticlesOfCat(
        options,
      );
    };
  }
  async getproductsOfCategory(categoryId: number) {
    if (this.productsOfCategory[categoryId]) {
      return this.productsOfCategory[categoryId];
    }
    this.setArticlesOfCat({
      category: categoryId,
      products: await API.getProductsOfCategory(categoryId),
    });
    return this.productsOfCategory[categoryId];
  }

  public static getproductsOfCategory(categoryId: number) {
    return (dispatcher: any, getState: any) => {
      return new RAppState(
        undefined,
        dispatcher,
        getState,
      ).getproductsOfCategory(categoryId);
    };
  }
  updateCategoryHash() {
    if (this._state) {
      this.categories.forEach(c => {
        this.categoryById[c.id] = c;
      });
    } else {
      if (this._dispatch) {
        this._dispatch({ type: AppStateEnums.AppState_updateCategoryHash });
      }
    }
  }

  public static updateCategoryHash() {
    return (dispatcher: any, getState: any) => {
      return new RAppState(
        undefined,
        dispatcher,
        getState,
      ).updateCategoryHash();
    };
  }
  updateAlvs(alvList: schema.alv[]) {
    if (this._state) {
      for (const alv of alvList) {
        this.alvById[alv.id] = alv;
      }
    } else {
      if (this._dispatch) {
        this._dispatch({
          type: AppStateEnums.AppState_updateAlvs,
          payload: alvList,
        });
      }
    }
  }

  public static updateAlvs(alvList: schema.alv[]) {
    return (dispatcher: any, getState: any) => {
      return new RAppState(undefined, dispatcher, getState).updateAlvs(alvList);
    };
  }
  async searchProducts(value: string) {
    console.log("Search ", value);
    this.searchOn = true;
    try {
      this.searchResult = await API.searchProducts(value);
    } catch (e) {}
    this.searchOn = false;
  }

  public static searchProducts(value: string) {
    return (dispatcher: any, getState: any) => {
      return new RAppState(undefined, dispatcher, getState).searchProducts(
        value,
      );
    };
  }
  async getCategories() {
    if (this.categories.length > 0) {
      return this.categories;
    }
    const [settings] = await API.fetchAllShopSettings({ offset: 0, limit: 1 });
    if (settings) {
      this.shopSettings = settings;
    }
    const alvs = await API.fetchAllAlv({ limit: 1000, offset: 0 });
    this.updateAlvs(alvs);
    categoryLoader = categoryLoader || API.getCategories();
    this.categories = await categoryLoader;
    this.updateCategoryHash();
    return this.categories;
  }

  public static getCategories() {
    return (dispatcher: any, getState: any) => {
      return new RAppState(undefined, dispatcher, getState).getCategories();
    };
  }
  async getArticles() {
    return [];
    // if (this.cart.length > 0) {
    //   this.updateCartSum();
    // }
    // if (this.articles.length > 0) {
    //   return this.articles;
    // }
    // if (articeLoader) {
    //   await articeLoader;
    //   await new Promise(res => {
    //     setTimeout(res, 1);
    //   });
    //   return this.articles;
    // }
    // console.log("Calling get articles");
    // console.time("getArticles");
    // articeLoader = articeLoader || API.getArticles();
    // this.articles = (await articeLoader).map(item => {
    //   const [articleid, categoryid, heading, prices, props] = item;
    //   return {
    //     articleid,
    //     categoryid,
    //     heading,
    //     prices: prices.map(p => {
    //       return { name: p[0], value: p[1] };
    //     }),
    //     props: props.map(p => ({ name: p[0], intvalue: Number(p[1]) }))
    //   };
    // });
    // articeLoader = null;
    // console.timeEnd("getArticles");
    // this.updateCartSum();
    // return this.articles;
  }

  public static getArticles() {
    return (dispatcher: any, getState: any) => {
      return new RAppState(undefined, dispatcher, getState).getArticles();
    };
  }
}

export const AppStateEnums = {
  AppState_idCounter: "AppState_idCounter",
  AppState_cartTotalSum: "AppState_cartTotalSum",
  AppState_articles: "AppState_articles",
  AppState_categories: "AppState_categories",
  AppState_categoryById: "AppState_categoryById",
  AppState_categoryArticles: "AppState_categoryArticles",
  AppState_shopSettings: "AppState_shopSettings",
  AppState_searchOn: "AppState_searchOn",
  AppState_searchResult: "AppState_searchResult",
  AppState_productsOfCategory: "AppState_productsOfCategory",
  AppState_alvById: "AppState_alvById",
  AppState_cart: "AppState_cart",
  AppState_cartError: "AppState_cartError",
  AppState_order: "AppState_order",
  AppState_calcCategoryArticles: "AppState_calcCategoryArticles",
  AppState_newOrderFromCart: "AppState_newOrderFromCart",
  AppState_setOrder: "AppState_setOrder",
  AppState_initOrderFromCart: "AppState_initOrderFromCart",
  AppState_updateCartSum: "AppState_updateCartSum",
  AppState_clearCart: "AppState_clearCart",
  AppState_addToCart: "AppState_addToCart",
  AppState_changeItemCnt: "AppState_changeItemCnt",
  AppState_removeFromCart: "AppState_removeFromCart",
  AppState_setArticlesOfCat: "AppState_setArticlesOfCat",
  AppState_updateCategoryHash: "AppState_updateCategoryHash",
  AppState_updateAlvs: "AppState_updateAlvs",
};

export const AppStateReducer = (
  state: IAppState = initAppState(),
  action: any,
) => {
  return immer.produce(state, (draft: IAppState) => {
    switch (action.type) {
      case AppStateEnums.AppState_idCounter:
        new RAppState(draft).idCounter = action.payload;
        break;
      case AppStateEnums.AppState_cartTotalSum:
        new RAppState(draft).cartTotalSum = action.payload;
        break;
      case AppStateEnums.AppState_articles:
        new RAppState(draft).articles = action.payload;
        break;
      case AppStateEnums.AppState_categories:
        new RAppState(draft).categories = action.payload;
        break;
      case AppStateEnums.AppState_categoryById:
        new RAppState(draft).categoryById = action.payload;
        break;
      case AppStateEnums.AppState_categoryArticles:
        new RAppState(draft).categoryArticles = action.payload;
        break;
      case AppStateEnums.AppState_shopSettings:
        new RAppState(draft).shopSettings = action.payload;
        break;
      case AppStateEnums.AppState_searchOn:
        new RAppState(draft).searchOn = action.payload;
        break;
      case AppStateEnums.AppState_searchResult:
        new RAppState(draft).searchResult = action.payload;
        break;
      case AppStateEnums.AppState_productsOfCategory:
        new RAppState(draft).productsOfCategory = action.payload;
        break;
      case AppStateEnums.AppState_alvById:
        new RAppState(draft).alvById = action.payload;
        break;
      case AppStateEnums.AppState_cart:
        new RAppState(draft).cart = action.payload;
        break;
      case AppStateEnums.AppState_cartError:
        new RAppState(draft).cartError = action.payload;
        break;
      case AppStateEnums.AppState_order:
        new RAppState(draft).order = action.payload;
        break;
      case AppStateEnums.AppState_calcCategoryArticles:
        new RAppState(draft).calcCategoryArticles();
        break;
      case AppStateEnums.AppState_newOrderFromCart:
        new RAppState(draft).newOrderFromCart();
        break;
      case AppStateEnums.AppState_setOrder:
        new RAppState(draft).setOrder(action.payload);
        break;
      case AppStateEnums.AppState_initOrderFromCart:
        new RAppState(draft).initOrderFromCart();
        break;
      case AppStateEnums.AppState_updateCartSum:
        new RAppState(draft).updateCartSum();
        break;
      case AppStateEnums.AppState_clearCart:
        new RAppState(draft).clearCart();
        break;
      case AppStateEnums.AppState_addToCart:
        new RAppState(draft).addToCart(action.payload);
        break;
      case AppStateEnums.AppState_changeItemCnt:
        new RAppState(draft).changeItemCnt(action.payload);
        break;
      case AppStateEnums.AppState_removeFromCart:
        new RAppState(draft).removeFromCart(action.payload);
        break;
      case AppStateEnums.AppState_setArticlesOfCat:
        new RAppState(draft).setArticlesOfCat(action.payload);
        break;
      case AppStateEnums.AppState_updateCategoryHash:
        new RAppState(draft).updateCategoryHash();
        break;
      case AppStateEnums.AppState_updateAlvs:
        new RAppState(draft).updateAlvs(action.payload);
        break;
    }
  });
};
/********************************
 * React Context API component   *
 ********************************/
export const AppStateContext = React.createContext<IProps>(
  initWithMethodsAppState(),
);
export const AppStateConsumer = AppStateContext.Consumer;
let instanceCnt = 1;
export class AppStateProvider extends React.Component {
  public state: IAppState = initAppState();
  public lastSetState: IAppState;
  private __devTools: any = null;
  constructor(props: any) {
    super(props);
    this.lastSetState = this.state;
    this.calcCategoryArticles = this.calcCategoryArticles.bind(this);
    this.newOrderFromCart = this.newOrderFromCart.bind(this);
    this.setOrder = this.setOrder.bind(this);
    this.saveCartAsRecipe = this.saveCartAsRecipe.bind(this);
    this.findRecipe = this.findRecipe.bind(this);
    this.saveOrder = this.saveOrder.bind(this);
    this.getPaymentData = this.getPaymentData.bind(this);
    this.initOrderFromCart = this.initOrderFromCart.bind(this);
    this.updateCartSum = this.updateCartSum.bind(this);
    this.getOrder = this.getOrder.bind(this);
    this.clearCart = this.clearCart.bind(this);
    this.addToCart = this.addToCart.bind(this);
    this.changeItemCnt = this.changeItemCnt.bind(this);
    this.removeFromCart = this.removeFromCart.bind(this);
    this.setArticlesOfCat = this.setArticlesOfCat.bind(this);
    this.getproductsOfCategory = this.getproductsOfCategory.bind(this);
    this.updateCategoryHash = this.updateCategoryHash.bind(this);
    this.updateAlvs = this.updateAlvs.bind(this);
    this.searchProducts = this.searchProducts.bind(this);
    this.getCategories = this.getCategories.bind(this);
    this.getArticles = this.getArticles.bind(this);
    const devs = window["__REDUX_DEVTOOLS_EXTENSION__"]
      ? window["__REDUX_DEVTOOLS_EXTENSION__"]
      : null;
    if (devs) {
      this.__devTools = devs.connect({ name: "AppState" + instanceCnt++ });
      this.__devTools.init(this.state);
      this.__devTools.subscribe((msg: any) => {
        if (msg.type === "DISPATCH" && msg.state) {
          this.setState(JSON.parse(msg.state));
        }
      });
    }
  }
  public componentWillUnmount() {
    if (this.__devTools) {
      this.__devTools.unsubscribe();
    }
  }
  public setStateSync(state: IAppState) {
    this.lastSetState = state;
    this.setState(state);
  }
  calcCategoryArticles() {
    const nextState = immer.produce(this.state, (draft: IAppState) =>
      new RAppState(draft).calcCategoryArticles(),
    );
    if (this.__devTools) {
      this.__devTools.send("calcCategoryArticles", nextState);
    }
    this.setStateSync(nextState);
  }
  newOrderFromCart() {
    const nextState = immer.produce(this.state, (draft: IAppState) =>
      new RAppState(draft).newOrderFromCart(),
    );
    if (this.__devTools) {
      this.__devTools.send("newOrderFromCart", nextState);
    }
    this.setStateSync(nextState);
  }
  setOrder(o: model.Order) {
    const nextState = immer.produce(this.state, (draft: IAppState) =>
      new RAppState(draft).setOrder(o),
    );
    if (this.__devTools) {
      this.__devTools.send("setOrder", nextState);
    }
    this.setStateSync(nextState);
  }
  async saveCartAsRecipe() {
    return new RAppState(
      undefined,
      (action: any) => {
        const nextState = AppStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ AppState: this.lastSetState }),
    ).saveCartAsRecipe();
  }
  async findRecipe(value: string) {
    return new RAppState(
      undefined,
      (action: any) => {
        const nextState = AppStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ AppState: this.lastSetState }),
    ).findRecipe(value);
  }
  async saveOrder(o: model.Order) {
    return new RAppState(
      undefined,
      (action: any) => {
        const nextState = AppStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ AppState: this.lastSetState }),
    ).saveOrder(o);
  }
  async getPaymentData(uuid: string) {
    return new RAppState(
      undefined,
      (action: any) => {
        const nextState = AppStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ AppState: this.lastSetState }),
    ).getPaymentData(uuid);
  }
  initOrderFromCart() {
    const nextState = immer.produce(this.state, (draft: IAppState) =>
      new RAppState(draft).initOrderFromCart(),
    );
    if (this.__devTools) {
      this.__devTools.send("initOrderFromCart", nextState);
    }
    this.setStateSync(nextState);
  }
  updateCartSum() {
    const nextState = immer.produce(this.state, (draft: IAppState) =>
      new RAppState(draft).updateCartSum(),
    );
    if (this.__devTools) {
      this.__devTools.send("updateCartSum", nextState);
    }
    this.setStateSync(nextState);
  }
  async getOrder() {
    return new RAppState(
      undefined,
      (action: any) => {
        const nextState = AppStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ AppState: this.lastSetState }),
    ).getOrder();
  }
  clearCart() {
    const nextState = immer.produce(this.state, (draft: IAppState) =>
      new RAppState(draft).clearCart(),
    );
    if (this.__devTools) {
      this.__devTools.send("clearCart", nextState);
    }
    this.setStateSync(nextState);
  }
  addToCart(item: model.ShoppingCartItem) {
    const nextState = immer.produce(this.state, (draft: IAppState) =>
      new RAppState(draft).addToCart(item),
    );
    if (this.__devTools) {
      this.__devTools.send("addToCart", nextState);
    }
    this.setStateSync(nextState);
  }
  changeItemCnt(params: { item: model.ShoppingCartItem; cnt: number }) {
    const nextState = immer.produce(this.state, (draft: IAppState) =>
      new RAppState(draft).changeItemCnt(params),
    );
    if (this.__devTools) {
      this.__devTools.send("changeItemCnt", nextState);
    }
    this.setStateSync(nextState);
  }
  removeFromCart(removeItem: model.ShoppingCartItem) {
    const nextState = immer.produce(this.state, (draft: IAppState) =>
      new RAppState(draft).removeFromCart(removeItem),
    );
    if (this.__devTools) {
      this.__devTools.send("removeFromCart", nextState);
    }
    this.setStateSync(nextState);
  }
  setArticlesOfCat(options: {
    category: number;
    products: model.ProductMeta[];
  }) {
    const nextState = immer.produce(this.state, (draft: IAppState) =>
      new RAppState(draft).setArticlesOfCat(options),
    );
    if (this.__devTools) {
      this.__devTools.send("setArticlesOfCat", nextState);
    }
    this.setStateSync(nextState);
  }
  async getproductsOfCategory(categoryId: number) {
    return new RAppState(
      undefined,
      (action: any) => {
        const nextState = AppStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ AppState: this.lastSetState }),
    ).getproductsOfCategory(categoryId);
  }
  updateCategoryHash() {
    const nextState = immer.produce(this.state, (draft: IAppState) =>
      new RAppState(draft).updateCategoryHash(),
    );
    if (this.__devTools) {
      this.__devTools.send("updateCategoryHash", nextState);
    }
    this.setStateSync(nextState);
  }
  updateAlvs(alvList: schema.alv[]) {
    const nextState = immer.produce(this.state, (draft: IAppState) =>
      new RAppState(draft).updateAlvs(alvList),
    );
    if (this.__devTools) {
      this.__devTools.send("updateAlvs", nextState);
    }
    this.setStateSync(nextState);
  }
  async searchProducts(value: string) {
    return new RAppState(
      undefined,
      (action: any) => {
        const nextState = AppStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ AppState: this.lastSetState }),
    ).searchProducts(value);
  }
  async getCategories() {
    return new RAppState(
      undefined,
      (action: any) => {
        const nextState = AppStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ AppState: this.lastSetState }),
    ).getCategories();
  }
  async getArticles() {
    return new RAppState(
      undefined,
      (action: any) => {
        const nextState = AppStateReducer(this.lastSetState, action);
        if (this.__devTools) {
          this.__devTools.send(action.type, nextState);
        }
        this.setStateSync(nextState);
      },
      () => ({ AppState: this.lastSetState }),
    ).getArticles();
  }
  public render() {
    return (
      <AppStateContext.Provider
        value={{
          ...this.state,
          calcCategoryArticles: this.calcCategoryArticles,
          newOrderFromCart: this.newOrderFromCart,
          setOrder: this.setOrder,
          saveCartAsRecipe: this.saveCartAsRecipe,
          findRecipe: this.findRecipe,
          saveOrder: this.saveOrder,
          getPaymentData: this.getPaymentData,
          initOrderFromCart: this.initOrderFromCart,
          updateCartSum: this.updateCartSum,
          getOrder: this.getOrder,
          clearCart: this.clearCart,
          addToCart: this.addToCart,
          changeItemCnt: this.changeItemCnt,
          removeFromCart: this.removeFromCart,
          setArticlesOfCat: this.setArticlesOfCat,
          getproductsOfCategory: this.getproductsOfCategory,
          updateCategoryHash: this.updateCategoryHash,
          updateAlvs: this.updateAlvs,
          searchProducts: this.searchProducts,
          getCategories: this.getCategories,
          getArticles: this.getArticles,
        }}
      >
        {" "}
        {this.props.children}
      </AppStateContext.Provider>
    );
  }
}
