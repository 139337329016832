import * as React from "react";
import { BrowserRouter, Route, Switch, Link, Redirect } from "react-router-dom";

import { Main, PlainComp } from "./Main";
import { StateConnector, IProps } from "../redux/reducers/AppState";

import axios from "axios";
import { FrontendAPI } from "../backend";

type RouterProps = IProps & { history: History };

class RouterClass extends React.Component<RouterProps> {
  state = {
    hasCheckedUser: false,
  };
  constructor(props: RouterProps) {
    super(props);
    this.state = {
      hasCheckedUser: false,
    };
  }
  render() {
    return (
      <BrowserRouter>
        <div>
          <Switch>
            <Route path="/search/:search" component={Main} />
            <Route path="/category/:categoryid" component={Main} />
            <Route path="/checkout/:checkout" component={Main} />
            <Route path="/order/:orderid" component={Main} />
            <Route path="/payment/:paymentstatus/" component={Main} />
            <Route path="/rekisteriseloste/" component={PlainComp} />
            <Route path="/yhteystiedot/" component={PlainComp} />
            <Route path="/newsletter/unsubscribe" component={PlainComp} />
            <Route path="/" component={Main} />
          </Switch>
        </div>
      </BrowserRouter>
    );
  }
}

export const Routes = StateConnector(RouterClass);
