import * as React from "react";
import { createContext, useContext, Component } from "react";
import * as container from "../redux/reducers/AppState";

export const AppContext = createContext<container.IProps>(
  {} as container.IProps
);

class MainAppContextProvider extends Component<container.IProps> {
  constructor(props: container.IProps) {
    super(props);
  }
  public render() {
    return (
      <AppContext.Provider value={this.props}>
        {this.props.children}
      </AppContext.Provider>
    );
  }
}

export const AppContextProvider = container.StateConnector(
  MainAppContextProvider
);

export function useAppContext() {
  return useContext(AppContext);
}
